import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  getAllWithdrawals,
  transferbtmeta,
  fetchUserBalaance,
  withdrawalApproved,
} from "../../common/Api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import * as XLSX from "xlsx"; // For Excel export
import { saveAs } from "file-saver"; // For file saving
import { formatDate } from "../../common/common";
import useDebounce from "../../hooks/useDebounce";

function Withdrawls() {
  const { user, token } = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState(""); // New state for search input
  const [debounceSearchQuery, setDebounceSearchQuery] = useState("");
  const [loading, setLoading] = useState(false); // New loading state
  const [selectedFund, setSelectedFund] = useState({});
  const handleClose = () => setShow(false);
  const [approvedloading, setApprovedLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState(""); // Status filter
  const [filterDate, setFilterDate] = useState(formatDate(new Date())); // Date filter
  // const [filterDate, setFilterDate] = useState(); // Date filter
  const [itemsPerPage, setItemsPerPage] = useState(10);
  //12-12-2024 withdrawalapprove
  const [withdrawalapprove, setWithdrawalapprove] = useState(false);
  const [userbalance, setUserBalance] = useState({});
  const [id, setId] = useState("");
  const [proceedamount, setProceedAmount] = useState("");
  const [proccedaddress, setProceedAddress] = useState("");
  const [proccedstatus, setProceedStatus] = useState(0);
  const [processExport, setProcessExport] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const hasPageChanged = useRef(false);
  const currentPageRef = useRef(1);

  const ranksData = [
    { id: 1, name: "Basic Club" },
    { id: 2, name: "Meta Founder Club" },
    { id: 3, name: "Meta Star Club" },
    { id: 4, name: "Meta Royal Club" },
    { id: 5, name: "Meta Prime Club" },
    { id: 6, name: "Meta Ambassador Club" },
    { id: 7, name: "Meta Crown Ambassador Club" },
    { id: 8, name: "Meta Universal Crown Ambassador Club" },
  ];

  // Fetch withdrawal data
  const get_user_withdrawal = async (page = 1, search = "") => {
    setLoading(true);
    try {
      const datas = await getAllWithdrawals({
        token,
      });
      setFilteredData(datas?.data || []);
      setData(datas?.data || []);
      // setTotalPages(datas?.pagination.totalPages);
      // setTotalRecords(datas?.pagination.totalRecords);
      setLoading(false);
      hasPageChanged.current = true;
    } catch (error) {
      toast.error("Failed to fetch data");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = useDebounce((query) => {
    setDebounceSearchQuery(query);
  }, 500);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  const showSingleModel = (row) => {
    setShow(true);
    setSelectedFund(row);
  };

  const handlePayBtMeta = async (row, matchId, idd, amount, address) => {
    setSelectedFund(row);
    setWithdrawalapprove(true);
    const status = document.querySelector(`#status-${row._id}`).value;
    setProceedStatus(status);
    const data = await fetchUserBalaance(matchId, token);
    setUserBalance(data.data);
    setId(idd);
    setProceedAmount(amount);
    setProceedAddress(address);
  };

  const finalApproved = async () => {
    setApprovedLoading(true);
    try {
      let hashs = "";
      if (proccedstatus == 1) {
        const data = await transferbtmeta(proceedamount, proccedaddress);

        hashs = data;
      }
      const submitdata = {
        status: proccedstatus,
        hash: hashs,
      };

      const approved = await withdrawalApproved(id, submitdata, token);
      if (approved.status == true) {
        setWithdrawalapprove(false);
        Swal.fire({
          icon: "success",
          title: "",
          text: approved.message,
        });

        setFilteredData(
          (prevData) =>
            prevData?.map((el) =>
              el._id === id ? { ...el, status: parseInt(proccedstatus) } : el
            ) || []
        );

        setApprovedLoading(false);

        // setInterval(() => {
        //   window.location.reload();
        // }, 2000);
      }
    } catch (error) {
      setWithdrawalapprove(false);
      setApprovedLoading(false);
      console.log(error);
    }
  };

  // Columns for the DataTable
  const columns = [
    {
      name: "#",
      selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
      width: "50px",
    },
    { name: "Withdrawal ID", selector: (row) => row?._id || "-NA-" },
    { name: "Name", selector: (row) => row.userId?.username || "-NA-" },
    { name: "Email", selector: (row) => row.userId?.email },
    { name: "Amount", selector: (row) => `${Math.abs(row.amount)} BTMETA` },
    {
      name: "wallet",
      selector: (row) => (
        <>
          {row.type == 0 ? (
            <>
              <small className="btn btn-success btn-xs">Staking Wallet</small>
            </>
          ) : row.type == 1 ? (
            <>
              <small className="btn btn-danger btn-xs">Affiliate Wallet</small>
            </>
          ) : row.type == 3 ? (
            <>
              <small className="btn btn-warning btn-xs">Royality Wallet</small>
            </>
          ) : (
            <>
              <small className="btn btn-info btn-xs">Refferal Wallet</small>
            </>
          )}
        </>
      ),
    },
    {
      name: "Credit Date",
      selector: (row) => new Date(row.creditDate).toLocaleString(),
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          {row.status === 1 ? (
            <small className="btn btn-success btn-xs">Approved</small>
          ) : row.status === 2 ? (
            <small className="btn btn-danger btn-xs">Reject</small>
          ) : (
            <small className="btn btn-warning btn-xs">Pending</small>
          )}
        </>
      ),
    },
    {
      name: "Change Status",
      selector: (row) => (
        <>
          {row.status != 1 && (
            <div className="status-change d-flex">
              <select className="form-control me-2" id={`status-${row._id}`}>
                <option value="1">select Option</option>
                <option value="1">Approved</option>
                <option value="2">Reject</option>
              </select>
              <button
                className="btn-xs"
                onClick={() =>
                  handlePayBtMeta(
                    row,
                    row.matchId,
                    row._id,
                    Math.abs(row.amount),
                    row.toaddress
                  )
                }
                disabled={approvedloading}
              >
                {" "}
                {approvedloading ? "Process.." : "Submit"}{" "}
              </button>
            </div>
          )}
        </>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <i
          className="fas fa-eye"
          style={{ color: "green", cursor: "pointer" }}
          onClick={() => showSingleModel(row)}
        ></i>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const calculateProfileCompleteness = (formValues) => {
    const totalFields = 10; // Total number of fields (including otp if needed)
    let filledFields = 0;

    // Check each field in formValues
    for (const key in formValues) {
      // Check if the field is filled (non-empty)
      filledFields++;
    }

    // Calculate completeness percentage
    const completenessPercentage = (filledFields / totalFields) * 100;

    // Optionally, you can round it to the nearest whole number
    return Math.round(completenessPercentage);
  };

  // Export Data to Excel
  const handleExport = async () => {
    setProcessExport(true);

    try {
      // Generate exportData with async call for each user
      const exportData = await Promise.all(
        filteredData.map(async (row) => {
          // Fetch wallet balances asynchronously for each user
          const walletBalances = await fetchUserBalaance(row.matchId, token);

          // Ensure the structure is correct if walletBalances is returned as null or undefined
          const balances = walletBalances?.data?.data || {
            staking: 0,
            affility: 0,
            royalty: 0,
            referral: 0,
          };

          // Add the withdrawal amount to the respective wallet balance based on the type
          let updatedBalances = { ...balances };

          if (row.type === 0) {
            // Staking Wallet
            updatedBalances.staking += Math.abs(row.amount);
          } else if (row.type === 1) {
            // Affiliate Wallet
            updatedBalances.affility += Math.abs(row.amount);
          } else if (row.type === 3) {
            // Royalty Wallet
            updatedBalances.royalty += Math.abs(row.amount);
          } else {
            // Direct Referral Wallet
            updatedBalances.referral += Math.abs(row.amount);
          }

          return {
            Name: row.userId?.username || "-NA-",
            Email: row.userId?.email,
            "Withdrwal amount": `${Math.abs(row.amount)} BTMETA`,
            "Wallet ":
              row.type === 0
                ? "Staking Wallet"
                : row.type === 1
                ? "Affiliate Wallet"
                : row.type === 3
                ? "Royality Wallet"
                : "Direct Referral Wallet",
            "Date and time (withdrwal)": new Date(
              row.creditDate
            ).toLocaleString(),
            "Date and time (joining date)": new Date(
              row.userId?.createdAt
            ).toLocaleString(),
            "Staking wallet balance": updatedBalances.staking,
            "affiliate wallet balance": updatedBalances.affility,
            "Royalty wallet balance": updatedBalances.royalty,
            "Direct refferal wallet balance": updatedBalances.referral,
            Rank:
              ranksData.find((rank) => rank.id === row.userId?.btRank)?.name ||
              "-NA-",
            "To wallet address": row.toaddress,
            "Transaction hash": row.hash,
            "Profile Percentage": calculateProfileCompleteness({
              username: row.userId?.username || "",
              email: row.userId?.email || "",
              mobile: row.userId?.btmobile || "",
              depositWallet: row.userId?.depositWallet || "",
              gender: row.userId?.gender || "",
              dob: row.userId?.dob || "",
              twitter: row.userId?.twitter || "",
              telegram: row.userId?.telegram || "",
              instagram: row.userId?.instagram || "",
              lastname: row.userId?.lastname || "",
            }), // Assuming profilePercentage is available
            Status:
              row.status === 1
                ? "Approved"
                : row.status === 2
                ? "Rejected"
                : "Pending",
          };
        })
      );

      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Withdrawals");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(blob, "Withdrawals.xlsx");
    } catch (error) {
      console.error("Error during export:", error);
      setProcessExport(false);
    } finally {
      setProcessExport(false); // Reset loading after the export is complete
    }
  };

  useEffect(() => {
    get_user_withdrawal(currentPage);
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      filterData();
    }
  }, [searchQuery, filterStatus, filterDate]);

  const filterData = () => {
    if (!data && data.length === 0) return;

    let filtered = [...data];

    filtered = filtered.filter(
      (row) =>
        row._id?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.userId?.username
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        row.userId?.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.remarks?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.amount.toString().includes(searchQuery)
    );

    // Filter by status
    if (filterStatus) {
      filtered = filtered.filter(
        (row) => row.status.toString() === filterStatus
      );
    }

    // Filter by date
    if (filterDate) {
      filtered = filtered.filter((row) => {
        const rowDate = new Date(row.creditDate).toISOString().split("T")[0];
        return rowDate === filterDate;
      });
    }

    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const handleWithdrawalCloseClose = () => {
    setWithdrawalapprove(false);
  };

  // const handlePageChange = ((page) => {
  //   console.count();
  //   currentPageRef.current = page;
  //   hasPageChanged.current = false;
  //   get_user_withdrawal(page, itemsPerPage, debounceSearchQuery);
  // });

  console.log("filterdata", filteredData);

  return (
    <>
      <div className="container-xxl flex-grow-1">
        <div className="mb-3 mt-3 d-flex justify-content-between">
          <div className="d-flex w-100 gap-2 flex-column flex-lg-row flex-md-row">
            {/* Search Input */}
            <input
              type="text"
              className="form-control my-0 my-lg-2 my-md-2"
              placeholder="Search by Name, Withdrawal ID, Email, Amount, Remarks"
              value={searchQuery}
              onChange={handleSearch}
            />

            {/* Status Filter */}
            <select
              className="form-control my-2"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
            >
              <option value="">All Status</option>
              <option value="1">Approved</option>
              <option value="2">Rejected</option>
              <option value="0">Pending</option>
            </select>

            {/* Date Filter */}
            <input
              type="date"
              className="form-control my-0 my-lg-2 my-md-2"
              value={filterDate}
              onChange={(e) => setFilterDate(e.target.value)}
            />

            {/* Export Button */}
            <Button
              className="submit-screen btn-xs fs-6 w-100 w-25 my-2"
              onClick={handleExport}
              style={{ borderRadius: "5px !important" }}
              disabled={processExport} // Disable the button while loading
            >
              {processExport ? "Processing..." : "Export to Excel"}
            </Button>
          </div>
        </div>

        {/* Loader: Show when data is loading */}
        {loading ? (
          <div style={{ textAlign: "center", marginTop: "50px" }}>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <p>Loading data...</p>
          </div>
        ) : (
          // DataTable: Show when data is loaded
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            // paginationServer
            highlightOnHover
            responsive
            paginationPerPage={itemsPerPage}
            paginationTotalRows={totalRecords}
            // onChangeRowsPerPage={(newRowsPerPage) => {
            //   setItemsPerPage(newRowsPerPage);
            // onChangePage={handlePageChange}
            // onChangePage={(page) => setCurrentPage(page)}
            noDataComponent={
              <p style={{ margin: "10px", textAlign: "center" }}>
                There are no records to display
              </p>
            }
          />
        )}

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title className="pb-0">
              <h4 className="mb-0">Transactions</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <div className="table-responsive">
                  <table className="table w-100">
                    <tbody className="text-nowrap">
                      <tr>
                        <td>
                          <b>Name</b>
                        </td>
                        <td>{selectedFund?.userId?.username}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Email</b>
                        </td>
                        <td>{selectedFund?.userId?.email}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Amount</b>
                        </td>
                        <td>{selectedFund?.amount} btmeta</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Remarks</b>
                        </td>
                        <td>{selectedFund?.remarks}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>From Address</b>
                        </td>
                        <td>{selectedFund?.fromaddress}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>To Address</b>
                        </td>
                        <td>{selectedFund?.toaddress}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Hash</b>
                        </td>
                        <td>{selectedFund?.hash}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Credit Date</b>
                        </td>
                        <td>
                          {new Date(selectedFund?.creditDate).toLocaleString()}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="lg"
          show={withdrawalapprove}
          onHide={handleWithdrawalCloseClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              User Balance Detail {selectedFund?.userId?.username}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <b>Email : </b>
              {selectedFund?.userId?.email}
            </p>
            <p>
              <b>Withdrawal Address : </b>
              {selectedFund?.toaddress}
            </p>
            <p>
              <b>Withdrawal from Wallet: </b>
              {selectedFund.type == 0 ? (
                <>
                  <small className="btn btn-success btn-xs">
                    Staking Wallet
                  </small>
                </>
              ) : selectedFund.type == 1 ? (
                <>
                  <small className="btn btn-danger btn-xs">
                    Affiliate Wallet
                  </small>
                </>
              ) : selectedFund.type == 3 ? (
                <>
                  <small className="btn btn-warning btn-xs">
                    Royality Wallet
                  </small>
                </>
              ) : (
                <>
                  <small className="btn btn-info btn-xs">Refferal Wallet</small>
                </>
              )}
            </p>
            <p>
              <b>Withdrawal Amount: </b> {Math.abs(selectedFund.amount)} BTMETA
            </p>
            <table className="table w-100">
              <tbody className="text-nowrap">
                <tr>
                  <td>
                    <b>Staking Wallet</b>
                  </td>
                  <td>{userbalance?.data?.staking} BTMETA</td>
                </tr>
                <tr>
                  <td>
                    <b>Affiliate Wallet</b>
                  </td>
                  <td>{userbalance?.data?.affility} BTMETA</td>
                </tr>
                <tr>
                  <td>
                    <b>Royality Wallet</b>
                  </td>
                  <td>{userbalance?.data?.royalty} BTMETA</td>
                </tr>
                <tr>
                  <td>
                    <b>Refferal Wallet</b>
                  </td>
                  <td>{userbalance?.data?.referral} BTMETA</td>
                </tr>
              </tbody>
            </table>
            <div className="text-center mt-3">
              <p>
                <strong>
                  {" "}
                  Are you sure to {proccedstatus == 1
                    ? "approved"
                    : "rejected"}{" "}
                  this withdrawal ?{" "}
                </strong>
              </p>
              {approvedloading == true ? (
                <p style={{ color: "green" }}>Loading....</p>
              ) : (
                <button onClick={finalApproved}>Submit</button>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleWithdrawalCloseClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Withdrawls;
