import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const DataTableViewTransaction = ({ data, title }) => {
    const [selectedFund, setSelectedFund] = useState({});
    const [show, setShow] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Set items per page


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const showSingleModel = (row) => {
        setShow(true);
        setSelectedFund(row);
    };

    const formatAmount = (amount) => {
        return (Math.floor(amount * 100) / 100).toFixed(2);
    };

    // Filtered data based on search input
    const filteredData = data?.data?.filter(item => {
        return (
            item.userId?.username?.toLowerCase().includes(searchText.toLowerCase()) ||
            item.userId?.email?.toLowerCase().includes(searchText.toLowerCase()) ||
            item.planId?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
            item.remarks?.toLowerCase().includes(searchText.toLowerCase())
        );
    });

    const columns = [
      {
        name: "#",
        selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
        width: "50px",
      },
      { name: "Withdrawal ID", selector: (row) => row?._id || "-NA-" },
      { name: "Name", selector: (row) => row.userId?.username || "-NA-" },
      { name: "Email", selector: (row) => row.userId?.email },
      // { name: 'Plan', selector: row => row.planId?.name },
      {
        name: "Amount",
        selector: (row) => `${formatAmount(row.amount)} btmeta`,
      },
      {
        name: "Credit Date",
        selector: (row) => new Date(row.creditDate).toLocaleString(),
      },
      {
        name: "Action",
        cell: (row) => (
          <i
            className="fas fa-eye"
            style={{ color: "green", cursor: "pointer" }}
            onClick={() => showSingleModel(row)}
          ></i>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];

    return (
      <>
        <h5 style={{ margin: "50px" }}>{title}</h5>
        <div className="container">
          {/* Search Input */}
          <input
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ marginBottom: "10px", padding: "8px", width: "100%" }}
          />

          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            highlightOnHover
            responsive
            paginationDefaultPage={currentPage}
            onChangePage={(page) => setCurrentPage(page)}
            noDataComponent={
              <p style={{ margin: "10px", textAlign: "center" }}>
                There are no records to display
              </p>
            }
          />

          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title className="pb-0">
                <h4 className="mb-0">Transactions</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table w-100">
                      <tbody className="text-nowrap">
                        <tr>
                          <td>
                            <b>Name</b>
                          </td>
                          <td>{selectedFund?.userId?.username}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Email</b>
                          </td>
                          <td>{selectedFund?.userId?.email}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Package</b>
                          </td>
                          <td>{selectedFund?.planId?.name}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Amount</b>
                          </td>
                          <td>{selectedFund?.amount} btmeta</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Remarks</b>
                          </td>
                          <td>{selectedFund?.remarks}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>From Address</b>
                          </td>
                          <td>{selectedFund?.fromaddress}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>To Address</b>
                          </td>
                          <td>{selectedFund?.toaddress}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Hash</b>
                          </td>
                          <td>{selectedFund?.hash}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Credit Date</b>
                          </td>
                          <td>
                            {new Date(
                              selectedFund?.creditDate
                            ).toLocaleDateString()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
};

export default DataTableViewTransaction;
