import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { url } from "../Components/Common/Url";
import { useSelector } from "react-redux";

const UploadedAttachmentsTable = () => {
  const { token } = useSelector((state) => state.user);
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(true);

  const getUploadedScreenshots = async () => {
    try {
      const { data } = await axios.get(
        `${url}/api/v2/bt/setting/get-attachments`,
        {
          headers: {
            token: `${token}`, // Include the token for authentication
          },
        }
      );
      setAttachments(data.data); // Assuming your API returns an array of attachments
    } catch (error) {
      console.error("Failed to fetch attachments:", error);
      //toast.error('Failed to fetch uploaded screenshots.');
    } finally {
      setLoading(false); // Stop loading in either case
    }
  };

  useEffect(() => {
    getUploadedScreenshots(); // Call the API function when the component mounts

    console.log(attachments);
  }, []);

  if (loading) {
    return <p>Loading uploaded screenshots...</p>; // Loading state
  }

  return (
    <div className="container-xxl flex-grow-1">
      <div className="mb-3 mt-3">
        <h4 className="card-header text-white pb-4">Uploaded Screenshots</h4>
        <div className="card-bodym table-responsive">
          <Table striped bordered-0 hover className="table align-middle">
            <thead className="text-nowrap text-center">
              <tr>
                <th>#</th>
                <th>User Email</th>
                <th>Filename</th>
                <th>Upload Date</th>
              </tr>
            </thead>
            <tbody className="text-nowrap text-center">
              {attachments.length > 0 ? (
                attachments.map((attachment, index) => (
                  <tr key={attachment._id}>
                    <td>{index + 1}</td>
                    <td>{attachment?.userId ? attachment?.userId?.email : '-Na-'}</td>
                    <td>
                      <a
                        href={`https://qbitdex.io:5000${attachment.screenshotUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Screenshot
                      </a>
                    </td>
                    <td>{new Date(attachment.createdAt).toLocaleString()}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    No attachments found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default UploadedAttachmentsTable;
