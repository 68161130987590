import "./style.css";

const ShimmerLoader = () => (
  <div
    className="shimmer"
    style={{
      display: "flex", // Arrange items in a row
      gap: "5px", // Add space between shimmer lines
      flexWrap: "wrap",
      width: "100%",
      padding: "10px",
    }}
  >
    <div
      className="shimmer-line"
      style={{ width: "100%", height: "50px" }} // Fixed width for shimmer lines
    ></div>
    <div
      className="shimmer-line"
      style={{ width: "100%", height: "50px" }}
    ></div>
    <div
      className="shimmer-line"
      style={{ width: "100%", height: "50px" }}
    ></div>
    <div
      className="shimmer-line"
      style={{ width: "100%", height: "50px" }}
    ></div>
    <div
      className="shimmer-line"
      style={{ width: "100%", height: "50px" }} // Fixed width for shimmer lines
    ></div>
    <div
      className="shimmer-line"
      style={{ width: "100%", height: "50px" }}
    ></div>
    <div
      className="shimmer-line"
      style={{ width: "100%", height: "50px" }}
    ></div>
    <div
      className="shimmer-line"
      style={{ width: "100%", height: "50px" }}
    ></div>
    <div
      className="shimmer-line"
      style={{ width: "100%", height: "50px" }}
    ></div>
    <div
      className="shimmer-line"
      style={{ width: "100%", height: "50px" }}
    ></div>
  </div>
);

export default ShimmerLoader;
