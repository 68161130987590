import React, { useEffect, useState } from "react";
import { btuser_single_data_get, api_get_setting_data } from "../../common/Api";
import { useSelector } from "react-redux";
import { url, Image } from "../Common/Url";

export default function UserProfile({ totalDeposit, totalWithdrawal }) {
  const { user, token } = useSelector((state) => state.user);
  const [users, setUsers] = useState({});
  const [metaBTPrice, setMetaBTPrice] = useState(0.00);

  const copyText = (code) => {
    navigator.clipboard.writeText(code);
    alert("Copied the text: " + code);
  };

  const getSettingData = async () => {
    try {
      const data = await api_get_setting_data(token);
      console.log(data.data);
      setMetaBTPrice(data.data?.usd_price);
    } catch (error) {
      //toast.error("Failed to fetch settings data.");
    }
  };

  const btGetSingleUserData = async () => {
    try {
      const data = await btuser_single_data_get(user._id, token);
      setUsers(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSettingData();
    btGetSingleUserData();
  }, []);

  const formatAmount = (amount) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  return (
    <div className="container-fluid px-2 px-lg-0 px-md-0 pb-5 pb-lg-0 pb-md-0 h-100">
      <div className="profile">
        <div className="timeDiv d-block d-lg-none d-md-none pb-3">
          <span>Thu May 30</span>
          <span>7:30 PM</span>
        </div>
        <div className="profile-logo">
          <img
            src={
              users?.profileImg
                ? `${Image}/uploads/${users?.profileImg}`
                : `${Image}/uploads/default-avatar.jpg`
            }
          />
          <div className="profile_name">
            <h6 className="mb-0 pb-1 fw-bold" style={{ color: "#C4C4C4" }}>
              Hi {users?.username}
            </h6>
            <p className="mb-0" style={{ color: "#C4C4C4" }}>
              {users?.email}
            </p>
          </div>
        </div>
        <div className="balance_card">
          <div className="balance_icon d-flex align-items-center gap-2">
            <i className="fa-brands fa-stack-exchange"></i>
            <span
              className="ms-1 text-white fw-bold"
              style={{ textTransform: "uppercase" }}
            >
              Total Deposit
            </span>
          </div>
          <div className="balance d-flex mt-3 mb-0 gap-2 align-items-end">
            <h4
              style={{ fontSize: "30px", color: "#ACA4EB" }}
              className="mb-0 fw-semibold text-wrap"
            >
              {formatAmount(totalDeposit)}
            </h4>
            <p className="mb-0 pb-1">BTMETA</p>
          </div>
          <span className="d-flex mb-4">
            {formatAmount(totalDeposit * metaBTPrice)} USDT
          </span>
        </div>

        <div className="balance_card">
          <div className="balance_icon d-flex align-items-center gap-2">
            <i className="fa-brands fa-stack-exchange"></i>
            <span
              className="ms-1 text-white fw-bold"
              style={{ textTransform: "uppercase" }}
            >
              Total Withdrawal
            </span>
          </div>
          <div className="balance d-flex mt-3 mb-0 gap-2 align-items-end">
            <h4
              style={{ fontSize: "30px", color: "#ACA4EB" }}
              className="mb-0 fw-semibold text-wrap"
            >
              {formatAmount(totalWithdrawal)}
            </h4>
            <p className="mb-0 pb-1">BTMETA</p>
          </div>
          <span className="d-flex mb-4">
            {formatAmount(totalWithdrawal * metaBTPrice)} USDT
          </span>
        </div>

        <div className="mt-2">
          <h6 className="text-start text-white">Refer & Enjoy Rewards</h6>
          <p className="text-start text-light">
            You'll get rewards against your referral acticities
          </p>
        </div>
        <div className="referal_fields ">
          <label>Referral Code:</label>
          <div className="input-box">
            <input
              type="text"
              placeholder={users?.btucode}
              style={{ paddingRight: "30px", position: "relative" }}
              readOnly={true}
            />
            <i
              className="fa-regular fa-copy"
              onClick={() => copyText(users?.btucode)}
            ></i>
          </div>
        </div>
        <div className="referal_fields mt-3">
          <label>Referral Link:</label>
          <div className="input-box">
            <input
              type="text"
              placeholder={`https://btsmart.ai/register/${user?.btucode}`}
              style={{ paddingRight: "30px", position: "relative" }}
              readOnly={true}
            />
            <i
              className="fa-regular fa-copy"
              onClick={() =>
                copyText(`https://btsmart.ai/register/${user?.btucode}`)
              }
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
}
