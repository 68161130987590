import React, { useEffect, useState } from "react";
import StakingTab from "./StakingTab";
import { get_stake_stat , get_stake_plan_update } from "../../../common/Api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function StakingComponent() {
  const { user, token } = useSelector((state) => state.user);
  const [staking, setStaking] = useState("");
  const [maxamount, setMaxAmount] = useState("");
  const [maxreceived, setMaxreceived] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [active, setActive] = useState("");
  const [width, setWidth] = useState(0);

  const [activestakingAmount, setActiveStakingAmount] = useState(0);

  const formatAmount = (amount) => {
    return parseFloat(amount).toFixed(2);
  };
  const staking_stat_user = async () => {
    try {
      const response = await get_stake_stat(user._id, token);
      //console.log(response);
      setStaking(response.staking);
      setMaxAmount(response.stat.maxamount);
      setMaxreceived(response.stat.maxreceived);
      setTotalCount(response.stat.count);
      setActive(response.stat.active);

      const total =
        response.stat.maxreceived /
        (response.stat.maxreceived + response.stat.maxamount);
      setWidth(total * 100);
    } catch (error) {
      toast.error(error);
    }
  };

  const getTotalAmountOfActive = async() =>{
    const response = await get_stake_plan_update(user._id, token, 1);
    //console.log(response.data);
    const totalAmount = response.data.reduce((sum, item) => sum + (item.amount || 0), 0);
    //console.log(totalAmount)
    setActiveStakingAmount(totalAmount)
  }

  useEffect(() => {
    staking_stat_user();
    getTotalAmountOfActive();
  }, [token]);

  return (
    <div className="dashboard-content overflow-hidden">
      <h2 className="text-white">My Staking Plans</h2>
      <div className="Rreward_received hide_mobile d-none d-lg-block d-md-block">
         Active Staking  <span style={{ fontSize: "12px" }}>{activestakingAmount && activestakingAmount} BTMETA</span>
        {/* <p style={{ fontSize: "13px" }}>
          Reward Received{" "}
          <span style={{ fontSize: "12px" }}>{staking} BTMETA</span>
        </p> */}
      </div>
      <div className="Rreward_received show_mobile">
        <p>
           Active Staking  <span style={{ fontSize: "12px" }}>{activestakingAmount && activestakingAmount} BTMETA</span>


          {/* Reward Received
          <br />
          <span>{staking} BTMETA</span> */}
        </p>
      </div>
      <div className="active_close mt-5 show_dest">
        <button
          type="button"
          className="btn button_staking btn-rounded btn-pill"
        >
          <span className="stakingNum">
            <span>{totalCount}</span>
          </span>
          <a href="#">Total Staking</a>
        </button>
        <button type="button" className="btn button_close">
          <span className="stakingNum">
            <span>{active}</span>
          </span>
          <a href="#">Total Active Staking</a>
        </button>
        <button type="button" className="btn button_staking">
          <span className="stakingNum">
            <span>{totalCount - active}</span>
          </span>
          <a href="#">Total Close Staking</a>
        </button>
        {/* <button type="button" className="btn button_close" style={{ background: '#F3C57C' }}>
                    <span className='stakingNum'><span>1{active}</span></span>
                    <a href="#">Expired Staking</a>
                </button> */}
      </div>
      <div className="bg-actGroup d-flex d-lg-none d-md-none">
        <div className="text-center d-flex flex-column justify-content-center align-items-center gap-2 text-white">
          <span className="bg-actBtn">{totalCount}</span>
          <a href="#" className="text-white">
            {" "}
            Total Staking
          </a>
        </div>
        <div className="text-center d-flex flex-column justify-content-center align-items-center gap-2 text-white">
          <span className="bg-actBtn bg-actBtn2">{active}</span>
          <a href="#" className="text-white">
          Total Active Staking
          </a>
        </div>
        {/* <div className='text-center d-flex flex-column justify-content-center align-items-center gap-2 text-white'>
                    <span className='bg-actBtn bg-actBtn3'>1</span>
                    <a href="#" className='text-white'>Expired Staking</a>
                </div> */}
      </div>
      <div className="progress_report">
        <p>
          <span>Received Staking</span>{" "}
          <span className="number">{formatAmount(maxreceived)} BTMETA</span>
        </p>
        <p>
          <span>Maximum Received</span>{" "}
          <span className="number">{formatAmount(maxamount)} BTMETA</span>
        </p>
      </div>
      <div className="progress">
        <div className="bar" style={{ width: `${width}%` }}>
          <p className="percent"> </p>
        </div>
      </div>
      <StakingTab />
    </div>
  );
}
