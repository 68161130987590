import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import {
  btuser_single_data_get,
  fetchRankCriteriaCondition,
  gerUserRank,
  get_single_user_busness,
  get_stake_plan_update,
  getDirectUserReferal,
  getNestedUsersByCode,
  getUserRankCriteria,
} from "../../common/Api";
import moment from "moment";
import ShimmerLoader from "./component/Shimmer";

const RankMaintainCriteriaPage = () => {
  const { user: userInfo, token } = useSelector((state) => state.user);
  const [rank, setRank] = useState({});
  const [nextRank, setNextRank] = useState({});
  const [rankCriteriaData, setRankCriteriaData] = useState({});
  const [users, setUsers] = useState({});
  const [rankCritica, setRankCritica] = useState([]);
  const [userRankCrData, setUserRankCrData] = useState();
  const [level, setLevel] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [activeStakingAmount, setActiveStakingAmount] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [user, setUser] = useState(null);
  const [directLegUsers, setDirectLegUsers] = useState([]);
  const [loadingLeg, setLoadingLeg] = useState(false);

  // Columns for DataTable
  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Name",
      selector: (row) =>
        row.username ? row.username : row.email.split("@")[0],
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Bt Code",
      selector: (row) => row.btucode,
    },
    {
      name: "Level",
      selector: (row) => row.level,
    },
    {
      name: "Sponsor Email",
      selector: (row) => row?.referredEmail,
      sortable: true,
    },
    {
      name: "Active Staking",
      selector: (row) => (
        <>
          {selectedUser === row._id && activeStakingAmount !== null ? (
            <span>{activeStakingAmount}</span>
          ) : (
            <button
              className="btn btn-success btn-sm"
              onClick={() => getTotalAmountOfActive(row._id)}
            >
              View
            </button>
          )}
        </>
      ),
      sortable: true,
    },
    {
      name: "Join Date & Time",
      selector: (row) => (
        <span>{moment(row.createdAt).format("MMMM Do YYYY, h:mm:ss a")}</span>
      ),
    },
  ];

  const btGetSingleUserData = async () => {
    try {
      const data = await btuser_single_data_get(userInfo._id, token);

      console.log("datadatadatadata", data);

      if (rankCritica.length > 0) {
        const currentRank = rankCritica.find(
          (rank) => rank.id === data.data.btRank
        );

        setRank(currentRank);

        const __rankcriteria = await fetchRankCriteriaCondition(
          currentRank.rankId,
          token
        );

        if (__rankcriteria?.status && __rankcriteria?.data) {
          setRankCriteriaData(__rankcriteria.data);
        }

        const nextRank = rankCritica.find(
          (rank) => rank.id === data.data.btRank + 1
        );
        setNextRank(nextRank);
      }

      setUsers(data.data);
    } catch (error) {
      console.error("Error fetching single user data:", error);
    }
  };

  const fetchUserRank = async () => {
    try {
      const response = await gerUserRank(token);

      if (response?.status && response?.data) {
        return response.data.map((rank) => ({
          id: rank.number,
          name: `${rank.name} Club`,
          rankId: rank._id,
        }));
      }
    } catch (error) {
      console.error("Error fetching ranks:", error);
    }
    // Fallback data
    return [
      { id: 8, name: "Meta Universal Crown Ambassador Club" },
      { id: 7, name: "Meta Crown Ambassador Club" },
      { id: 6, name: "META AMBASSADOR Club" },
      { id: 5, name: "META PRIME Club" },
      { id: 4, name: "META ROYAL Club" },
      { id: 3, name: "META STAR Club" },
      { id: 2, name: "META FOUNDER Club" },
      { id: 1, name: "BASIC Club" },
    ];
  };

  const findInnerUsers = (btucode, users, result = []) => {
    const directLegs = users.filter((user) => user.btreferal === btucode);
    result.push(...directLegs);
    directLegs.forEach((leg) => findInnerUsers(leg.btucode, users, result));
    return result;
  };

  const handleLevelChange = async (selectedBtucode) => {
    try {
      setLoadingLeg(true);
      setLevel(selectedBtucode);
      const data = await getNestedUsersByCode(selectedBtucode, token);
      setLoadingLeg(false);
      setFilteredUsers(data?.data);
    } catch (error) {
      console.error(error);
      setLoadingLeg(false);
    } finally {
      setLoadingLeg(false);
    }
  };

  const filterUsersByLevel = (rankHistory, level) => {
    if (!rankHistory || !rankHistory.legs || !rankHistory.totalUsers) {
      return [];
    }

    const legsAtLevel = Object.values(rankHistory.legs)
      .filter((leg) => leg.count === level)
      .flatMap((leg) => leg.users);

    const legUserIds = new Set(legsAtLevel.map((user) => user._id.toString()));

    return rankHistory.totalUsers.filter((user) =>
      legUserIds.has(user._id.toString())
    );
  };

  const getTotalAmountOfActive = async (userId) => {
    const data = await get_single_user_busness(userId);
    setActiveStakingAmount(data);
    setSelectedUser(userId);
  };

  const getDirectReferalData = async () => {
    try {
      const data = await getDirectUserReferal(userInfo?.btucode, token);

      const currentDate = new Date();

      const filteredData =
        data?.data
          // ?.filter((user) => {
          //   const userCreatedDate = new Date(user.createdAt);
          //   const diffTime = currentDate - userCreatedDate;
          //   const diffDays = diffTime / (1000 * 3600 * 24);
          //   return diffDays <= 30;
          // })
          ?.map((el) => el?.btucode) || [];

      console.log(filteredData, "filteredData");

      setDirectLegUsers(filteredData);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const data = await btuser_single_data_get(userInfo._id, token);
      const ranks = await fetchUserRank();
      setRankCritica(ranks);
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    getDirectReferalData();
  }, []);

  useEffect(() => {
    const getUserRankCr = async () => {
      try {
        const res = await getUserRankCriteria(token, userInfo._id);
        setUserRankCrData(res);
        setFilteredUsers(res?.totalUsers || []);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    getUserRankCr();
  }, []);

  useEffect(() => {
    if (rankCritica.length > 0) {
      btGetSingleUserData();
    }
  }, [rankCritica]);

  const findUserRank = (rankNumber) => {
    return rankCritica.find((rank) => rank.id === parseInt(rankNumber));
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center">Rank Maintain Criteria</h2>
      <div className="card p-4">
        <h4 style={{ textAlign: "left" }}>User Details</h4>
        <div
          className="row mb-3 text-left"
          style={{
            textAlign: "left",
          }}
        >
          <div className="col-md-12">
            <p>
              <strong>Name:</strong>{" "}
              {userInfo ? userInfo?.email.split("@")[0] : "N/A"}
            </p>
            <p>
              <strong>Email:</strong> {userInfo?.email ?? "N/A"}
            </p>
            <p>
              <strong>Current Rank:</strong> {rank?.name} (Minimum{" "}
              {rankCriteriaData?.newbusiness || 0} new business)
            </p>
            <p>
              <strong>Rank Maintained:</strong>{" "}
              {findUserRank(users?.rankMaintained)?.name || "N/A"}
            </p>
            <p>
              <strong>Rank Updated:</strong>{" "}
              {moment(users?.rankUpdatedAt).format("MMM DD, YYYY")}
            </p>
          </div>
        </div>

        <h4>Team and Community Details</h4>

        <div className="row mb-3">
          <div className="col-12 d-flex flex-wrap align-items-center justify-content-between">
            <div
              className="d-flex align-items-center mb-2 mb-md-0"
              style={{ maxWidth: "100%" }}
            >
              <label
                htmlFor="level"
                className="me-2 mb-0"
                style={{ color: "#ddaa26" }}
              >
                Select Team:
              </label>
              <select
                id="level"
                className="form-control"
                value={level}
                onChange={(e) => handleLevelChange(e.target.value)}
                style={{ maxWidth: "200px" }}
              >
                {directLegUsers &&
                  directLegUsers.length > 0 &&
                  directLegUsers?.map((ucode, i) => (
                    <>
                      <option key={i} value={ucode}>
                        Team {i + 1}
                      </option>
                    </>
                  ))}
              </select>
            </div>
            <div className="d-flex align-items-center">
              <label className="me-2 mb-0">
                <strong>Total Staking Users (last 30 days):</strong>
              </label>
              <span className="me-2">
                {userRankCrData?.totalStakingUsersCount || 0}
              </span>
              {/* {userRankCrData?.rankMaintain ? (
                <span style={{ color: "green" }}>✔️</span>
              ) : (
                <span style={{ color: "red" }}>❌</span>
              )} */}
            </div>
            <div className="d-flex align-items-center">
              <label className="me-2 mb-0">
                <strong>Total Registered Users (last 30 days):</strong>
              </label>
              <span className="me-2">
                {userRankCrData?.totalNewBusinesses || 0}
              </span>
              {/* {userRankCrData?.rankMaintain ? (
                <span style={{ color: "green" }}>✔️</span>
              ) : (
                <span style={{ color: "red" }}>❌</span>
              )} */}
            </div>
          </div>
        </div>

        {userRankCrData && !loadingLeg && (
          <DataTable
            columns={columns}
            data={filteredUsers || []}
            pagination
            highlightOnHover
            striped
            noDataComponent={
              <p style={{ margin: "10px", textAlign: "center" }}>
                No Community Members Found
              </p>
            }
            progressPending={loadingLeg}
          />
        )}
        {loadingLeg && <ShimmerLoader />}
      </div>
    </div>
  );
};

export default RankMaintainCriteriaPage;
